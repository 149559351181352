$_popover-arrow-border-size: 1px;
$_popover-arrow-border-color: #d9d8dd;

$_popover-arrow-size: 7px;
$_popover-arrow-back-size: $_popover-arrow-size + $_popover-arrow-border-size;

$_popover-arrow-offset-left: $_popover-arrow-size*2;
$_popover-arrow-back-offset-left: $_popover-arrow-offset-left - ($_popover-arrow-back-size - $_popover-arrow-size);

@mixin popover-reset(){
  color:  #4a494d !important;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-shadow: none;
  white-space: normal;
  word-wrap: break-word;
  text-transform: none;
  pointer-events: none;
  margin: 0;
  text-align: left;
}

.popover {
  position: absolute;
  visibility: hidden;
  left: 0;
  bottom: 100%;
  z-index: 1000;
  border-radius: $borderRadiusS;

  width: 230px;

  background: $white;
  border: 1px solid #d9d8dd;

  opacity: 0;

  -moz-transition: opacity .3s, margin .3s;
  -o-transition: opacity .3s, margin .3s;
  -webkit-transition: opacity .3s, margin .3s;
  transition: opacity .3s, margin .3s;

  @include popover-reset();

  &:before {
    content: '';
    position: absolute;
    left: $_popover-arrow-back-offset-left;
    bottom: -$_popover-arrow-back-size;
    width: 0;
    height: 0;
    border-color: $_popover-arrow-border-color transparent transparent;
    border-style: solid;
    border-width: $_popover-arrow-back-size $_popover-arrow-back-size 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: $_popover-arrow-offset-left;
    bottom: -$_popover-arrow-size;
    width: 0;
    height: 0;
    border-color: #f6f7f8 transparent transparent;
    border-style: solid;
    border-width: $_popover-arrow-size $_popover-arrow-size 0;
  }

  .popover-header {
    display: block;
    padding: $spacingS $spacingS $spacingXs;
    border-bottom: 1px dotted #d9d8dd;
  }

  .popover-content {
    display: block;
    padding: $spacingS;
  }

  .popover-footer {
    display: block;
    padding: $spacingS;
    background: #f6f7f8;
    border-top: 1px solid lighten(#d9d8dd, 10%);
    border-radius: 0 0 $borderRadiusS $borderRadiusS;
  }
}

/* On bottom */



.popover-bottom .popover {
  top: 100%;
  bottom: auto;
  margin: 0;

  &:before {
    top: -$_popover-arrow-back-size;
    border-color: transparent transparent $_popover-arrow-border-color;
    border-style: solid;
    border-width: 0 $_popover-arrow-back-size $_popover-arrow-back-size;
    left: $_popover-arrow-back-offset-left;
  }

  &:after {
    top: -$_popover-arrow-size;
    border-color: transparent transparent $white;
    border-style: solid;
    border-width: 0 $_popover-arrow-size $_popover-arrow-size;
  }
}

.popover-display {
  position: relative;
  *z-index: 10;

  &:hover,
  &:focus {
    .popover {
      visibility: visible;
      margin-bottom: 10px;
      opacity: 1;
    }
  }
}

.popover-display.popover-bottom {
  &:hover,
  &:focus {
    .popover {
      margin-top: 10px;
    }
  }
}


/* On left */

.popover-left .popover {
  right: 0;
  left: auto;

  &:before {
    right: $_popover-arrow-back-offset-left;
    left: auto;
  }

  &:after {
    right: $_popover-arrow-offset-left;
    left: auto;
  }
}

/* Popover sizes */

.popover-s { width: 230px; }
.popover-m { width: 300px; }
.popover-l { width: 400px; }
.popover-xl { width: 530px; }