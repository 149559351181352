// The commun output for a text
// ======================
.text {
  color: $gray2;
  font-size: 13px;

  ol, ul {
    margin: 0;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// A muted text
// --------------
.text-muted {
  color: $gray1;
}

// Bold text
// ----------------
.text-bold {
  font-weight: 600;
}

// The text bubble for when a text is selected
// ====================
.textbubble {
  $textbubble-width: 150px;
  $textbubble-height: 16px;
  position: absolute;
  background: white;
  opacity: 0.9;
  border: solid 4px #d3d3d6;
  border-radius: 6px;
  line-height: $textbubble-height;
  padding: 5px;
  margin-top: $textbubble-height * -4;

  &:hover {
    opacity: 1;
  }

  &:after,
  &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(211, 211, 214, 0);
    border-top-color: #d3d3d6;
    border-width: 10px;
    left: 50%;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(211, 211, 214, 0);
    border-top-color: #d3d3d6;
    border-width: 16px;
    left: 50%;
    margin-left: -16px;
  }

}
  
//new design type

// Links

a {
  color: $theme_activelink_color;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $baseFontFamily;
  font-weight: bold;
  margin-bottom: (1em * $baseLineHeightFontMultiplier / 2.5);
  color: lighten($baseFontColor, 10%);
}

.h1, .h2, .h3, .h4, .h5, .h6, .h-lead {
  font-family: $baseFontFamily;
  font-weight: normal;
}

h1, .h1 {
  font-size: 20px;
}

h2, .h2 {
  font-size: 18px;
}

h3, .h3 {
  font-size: 16px;
}

h4, .h4 {
  font-size: 15px;
}

h5, .h5 {
  font-size: 14px;
  padding-bottom: (1em * $baseLineHeightFontMultiplier / 3);
}

h6, .h6 {
  font-size: 13px;
  padding-bottom: (1em * $baseLineHeightFontMultiplier / 3);
}

.h-bx {
  border-bottom: 3px solid $purple3;
  font-size: 16px;
  padding: 0 0 7px 0;
  font-family: $baseFontFamily;
}
