.body-synthesis {
  .sudject {
    padding: 10px 0 0 10px;
  }
  &.synthesis_is_published {

  }
  .synthesisPanel-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: $white;
    .panel-editablearea {
      @extend %icon;
      &:before, &:after {
        font-size: 7px;
        content: '\e805';
        color: $black;
      }
      &:after {
        @include rotate(180);
        margin-left: 13px;
      }
      &:before {
        float: left;
        margin-right: 7px;
      }
    }
  }
  .synthesisPanel-introduction,
  .synthesisPanel-conclusion {
    color: $white;
  }
  .introduction {
    background-color: $theme_synthesis_header_bg;
    padding: 10px;
    .text-muted {
      color: $white;
    }
    .panel-editablearea {
      background-color: $theme_synthesis_header_bg;
      border-radius: 0px;
      margin: 0;
    }
  }
  .conclusion {
    background-color: $theme_synthesis_header_bg;
    padding: 10px;
    .text-muted {
      color: $white;
    }
    .panel-editablearea {
      margin: 0;
      background-color: $theme_synthesis_header_bg;
      border-radius: 0px;
    }
  }

}