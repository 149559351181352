$tableOfIdeasRowHeightSmallScreen:48px;
$minimizePanelIconHeight:32px;
$lockPanelIconHeight:40px;
$panelHeaderTitleHeight:50px;


@media screen and (max-width: 375px) {

// ********************* header *********************

    li.sub.logo-container.fl{
        display:none;
    }
    #tour_step_welcome{
        .icon-arrowright{
            display:none;
        }
        .debate-icon{ 
            margin-left: 10px;
        }
    }
// ********************* discussion panel *********************    
    .js_nameContainer{
        display: block;
        width: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

}

@media screen and (max-width: 840px) {
    #onlinedot {
        width:260px;
    }
}

@media screen and (max-width: 760px) {
    
    body{
        overflow:hidden;
        -webkit-overflow-scrolling: touch;
    }
    #wrapper{
        overflow:hidden;
    }
    .grid-pad{
        padding-top:10px;
        padding-left:30px;
        padding-right:10px;
    }
    .content-container{
        padding: 5px 0 5px 0;
    }
    
// ********************* panel *********************

    .panel-header{
        height:$tableOfIdeasRowHeightSmallScreen;
    }
    .js_minimizePanel{
        line-height:$minimizePanelIconHeight;
    }
    .js_lockPanel{
        line-height:$lockPanelIconHeight;
    }
    .panel-header-title{
        line-height:$panelHeaderTitleHeight!important;
    }
    .joinDiscussion{
        display:none;
    }
    
// ********************* ideaList panel *********************

    .navSidebar-panel .panelContents{
        min-width:320px;
    }
    .idealist-item,.idealist-bar,.idealist-arrow,.idealist-link,.idealist-noarrow,.idealist-link-last{
        height:$tableOfIdeasRowHeightSmallScreen;
        background-size:cover;
    }
    .idealist-title{
        line-height:$tableOfIdeasRowHeightSmallScreen - 2;
    }
    .groupBody .navSidebar .side-menu > li > a.nav {
        height:$tableOfIdeasRowHeightSmallScreen;
    }

// ********************* discussion panel *********************
    
    //Hide the on/off button in the discussion panel header
    .show-expert-mode{
        display:none!important;
    }
    .messageList-panel .panelContents{
        min-width:320px!important;
    }
    .ui-tag{
        white-space:normal!important;
    }
    .pal{
        padding:0!important;
    }
    .messagelist-children, .messageList-list, .message{
        display:block;
    }
    .message.viewStyleFullBody .message-body{
        min-width:initial!important;
    }
    .messageSend{
        a.img-left.avatar.img-rounded{
            display:none;
        }
        .messageSend-form{
            margin-left:0;
        }
    }
    .messageSend .messageSend-form .messageSend-buttons{
        width:100%;
    }
    .js_messageListView-print{
        display:none;
    }
    .js_messageBottomMenu.message-menu.message-menu-actions{
        margin: 20px 0 0 0;
    }
    a.js_messageReplyBtn.message-replybox-openbtn {
        margin: 0 6px 0 0;
    }
    .mtm{
        margin:20px 0 0 0!important;
    }
// ********************* synthesis accordion *********************

    .synthesisItem{
        line-height: 27px;
    }

// ********************* synthesis panel *********************

    .ideafamily-item.has-children > .ideafamily-body > .ideafamily-idea{
        padding-top: 5px;    
    }
    .message.viewStyleFullBody .message-body.body_format_text_html p, .announcementMessage.viewStyleFullBody .message-body.body_format_text_html p{
        margin-top:0;
    }
    .ideafamily-item .ideafamily-body > .ideafamily-idea{
        padding-top:6px;
    }

//  ********************* dropdown menu *********************
    
    .dropdown{
        .username{
            span{
                display:none;
            }
        }
    }
    .navbar-right .dropdown-menu{
        right:0;
        left: auto;
    }
    .dropdown-menu{
        li{
            a{
                text-overflow: ellipsis;
                width: 180px;
                overflow: hidden;
                padding: 0 20px;
                line-height:$tableOfIdeasRowHeightSmallScreen;
                &:hover{
                    background:none;
                }
            }
        }
        li:not(:last-child){
            a{
                border-bottom: 1px solid #e5e5e5;
            }
        }
        .divider{
            display:none;
        }
        .navbar-widget-creation-links{
            li{
                width:100%!important;
            }
        }
    }
    .message .message-menu .dropdown .dropdown-menu{
        min-width:220px;
        a{
            white-space:nowrap!important;
            min-width:220px;
            line-height:48px;
            text-overflow:ellipsis;
            overflow:hidden;
        }
    }
    
// ********************* header *********************
    .navbar .navbar-right ul .sub{
        line-height:53px;
    }
    .help{
        display:none;
    }
    .navbar-left{
        ul{
            li:last-child{
                width:70px;
            }
        }
    }
    .dot-offline{
        display:none!important;
    }
    .dot-online{
        display:block!important;
        width:130px;
        span{
            text-overflow:ellipsis;
        }
    }
    #header{
        height:$tableOfIdeasRowHeightSmallScreen;
    }
    .navbar.navbar-default{
        height:$tableOfIdeasRowHeightSmallScreen;
    }
    #groupContainer > .groupsContainer{
        top:$tableOfIdeasRowHeightSmallScreen;
    }
    .debate-title .debate-icon{
        line-height:$tableOfIdeasRowHeightSmallScreen;
        vertical-align:middle!important;
    }
    .navbar .navbar-left ul .sub{
        height:$tableOfIdeasRowHeightSmallScreen;
    }
    .navbar .navbar-right ul > li{
        height:$tableOfIdeasRowHeightSmallScreen;
        line-height:$tableOfIdeasRowHeightSmallScreen;
    }

// ********************* modal *********************

    .bbm-modal.bbm-modal--open{
        min-width: 250px!important;
        .groupContent{
            width:100%;
        }
        .messageList-panel{
            min-width:150px!important;
            width:100%!important;
        }
    }
    .popin-wrapper{
        padding:0;
    }
    .modal-ckeditorfield .bbm-modal{
        width:90%;
    }
    
// ********************* Login *********************

    .loginbox.sign-in{
        width: 100%;
        margin: 0;
        top: 0;
        position: absolute;
        height: 100%;
        overflow-y:scroll;
    }
    .loginbox header{
        border-radius:0;
    }
    .loginbox .signinWrapper{
        width: 98%;
    }
    .loginbox .socialPart{
        width: 98%;
    }
    
// ********************* Account/Profil *********************

    .admin-profile, .admin-account, .admin-notifications{
        .btn-primary{
            margin: 0 0 10px 0;
        }
        #content-container{
            padding: 10px 0 0 0;
        }
        label{
            padding: 0 0 10px 0;
        }
        .btn-cancel{
            display:none;
        }
        .mtxl{
            margin:0 0 20px 0;
            a{
                color:$gray;
                font-weight:600;
            }
        }
        .help-admin{
            font-size: 10px;
            padding: 0 5px 0 0;
        }
        .control-group{
            padding: 0 10px!important;
        }
        .core-form fieldset{
            margin: 0 0 20px 0;
        }
    }
}
