.contextPanel {
  height: 100%;
  overflow-y: auto;

  .row-major, .row-minor {
    clear: both;
    padding: 0px 4px 8px 4px;
  }
  .row-minor {
    /*min-height: 200px;*/
  }
  .row-major {
    background: #fff;
    /*height: 300px;*/
  }
  .block-half {
    width: 48%;
    display: inline-block;
    /*float: left;*/
    vertical-align: top;
  }
  .block-full {
    width: 100%;
  }

  .block-content {
    padding: 2px 10px;
  }

  .block-content ul li {
    margin-bottom: 6px;
  }

  #introduction-button-see-more {
    display: none;
  }

  /* CSS associated to the line chart */

  .data-line {
    stroke: steelblue;
    stroke-width: 2;
    fill: none;
    stroke-dasharray: 0;
  }

  .chart path.area {
    /*fill: lightsteelblue;*/
    fill: #9a3fd5;
  }

  .chart path {
    stroke: #eee;
    stroke-width: 2;
    stroke-dasharray: 0;
    fill: none;
  }

  text {
    font-family: Arial;
    font-size: 8pt;
    fill: #787878;
  }

  line {
    stroke: #CDCDCD;
    stroke-width: 0.5;
    /* stroke-dasharray: 3 3; */
    fill: none;
  }

  .data-point {
    stroke: steelblue;
    stroke-width: 2;
    fill: #FFF;
  }

  #chart {
    margin: 0;
  }

  .overlay {
    fill: none;
    pointer-events: all;
  }

  .focus circle {
    fill: rgba(255, 255, 255, 0.5);
    stroke: #aaa;
  }

  .focus text {
    fill: black;
    text-shadow: 0 0 2px rgba(220, 220, 220, 1);
  }

  .focus rect {
    /*fill: #fff;
    fill-opacity: 0.1;*/
    fill: url(#lineChartLegendGradient);
  }

  #lineChartLegendGradient .stop1, #lineChartLegendGradient .stop2, #lineChartLegendGradient .stop3 {
    stop-color: white;
  }
  #lineChartLegendGradient .stop1, #lineChartLegendGradient .stop3 {
    stop-opacity: 0;
  }
  #lineChartLegendGradient .stop2 {
    stop-opacity: 0.2;
  }

  /* CSS associated to the pie */

  .pie {
    height: 300px;
    vertical-align: top;
  }

  .pie_chart {
    margin: 0 auto;
    width: 100%;
    height: 50%;
    vertical-align: top;
    text-align: center;
  }

  .pie_chart_legend {
    margin-top: 45px;
    height: 35px;
  }

  .pie svg {
    margin: 0 auto;
    text-align: center;
  }

  .pie_chart .formg text {
    fill: white;
    text-shadow: 0 1px 1px rgba(150, 150, 150, 1);
  }

  .pie_chart path.form:hover, .pie_chart path.form.hover {
    stroke: #777;
  }

  .pie_chart path {
    stroke: #eee;
    stroke-dasharray: 0;
    fill: none;
    -webkit-transition: stroke 0.5s;
    transition: stroke 0.5s;
  }
}

.lastSynthesisTitle {
  .message-date {
    color: $gray1;
    font-size: 12px;
    opacity: 0.5;
    &:before {
      content: "\002022";
      padding-right: 0.3em;
    }
  }
}

#context-instigator {
  .instigator-logo{
    max-width: em(300);
    max-height: em(120);
  }
}

.context-objective.ctx_edit, 
.context-introduction.ctx_edit {
  cursor: text;
}

.partnersList{
  .partnersItem {
    display: table;
    width: em(200);
    height: em(200);
    a {
      display: table-cell;
      vertical-align: middle;
    }
    .partnerItemLogo{
      max-width: em(200);
      max-height: em(200);
    }
  }
}
