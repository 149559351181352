.separator { /* empty */ }



// Default spacer
// ==============
.spacer {
    @include clearfix();

    display: block;
    line-height: 10px;
    height: 10px;
}

// A big spacer
// +++++++++++
.spacer.spacer--big {
    line-height: 10px * 2;
    height: 10px * 2;
}

// A huge spacer
// +++++++++++
.spacer.spacer--huge {
    line-height: 10px * 4;
    height: 10px * 4;
}
