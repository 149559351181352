$marginLink: -30px;
$marginLinkbis: 30px;
$idea_in_synthesis_icon_hover: $theme_inactivelink_color;
$idea_in_synthesis_comment_hover: $theme_activelink_color;

.ideafamily-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    padding-left: 10px;
}
.ideafamily-item {
    .ideafamily-body {
      border-left: solid 1px #CCCCCC;
      margin-left: $marginLinkbis/2 - 1;
      position: relative;
    
        > .ideafamily-link {
            width: 30px;
            height: 40px;
            position:absolute;
            margin: 0;
          margin-left: $marginLink/2;
            background: url(#{$img-dir}/idealist/idealist-link-opaque.png) left top no-repeat;
        }
        > .ideafamily-idea {
          margin-left: $marginLinkbis/2;
          padding-left: $marginLinkbis/3; /* $marginLinkbis/2 = 15px was too far from the node icon, so instead, we use $marginLinkbis/3 = 10px */
        }
    }
    &.is-last-sibling {
        > .ideafamily-body {
            border-left: none;
          margin-left: $marginLinkbis/2;
            > .ideafamily-link {
                background: url(#{$img-dir}/idealist/idealist-link-last-opaque.png) left top no-repeat;
            }
        }
    }
    &.skip_parent {
      > .ideafamily-body {
        //margin-left: 3 * 30px/2 - 1;
        padding-left: 2 * $marginLinkbis/2 - 1;
        > .ideafamily-link {
          width: $marginLinkbis*2;
          margin-left: -3 * $marginLinkbis/2;
            background: url(#{$img-dir}/idealist/idealist-link-skip-parent.svg) left top no-repeat;
        }
      }
    }

    &.is-last-sibling.skip_parent {
      > .ideafamily-body {
        //margin-left: 3 * 30px/2 - 1;
        //padding-left:
        padding-left: 2 * $marginLinkbis/2 - 1;
        > .ideafamily-link {
          width: $marginLinkbis*2;
          margin-left: -3* $marginLinkbis/2;
            background: url(#{$img-dir}/idealist/idealist-link-skip-parent-no-children.svg) left top no-repeat;
        }
      }
    }
    
    &.level0 {
        > .ideafamily-body {
            border-left: none;
          margin-left: $marginLinkbis/2;
            > .ideafamily-link {
                background: url(#{$img-dir}/idealist/idealist-link-root.svg) left top no-repeat;
            }
        }
    }
    &.no-children.level0 {
      > .ideafamily-body {

        > .ideafamily-link {
            background: url(#{$img-dir}/idealist/idealist-link-root.svg) right top no-repeat;
        }
      }
    }
    &.has-children {
        > .ideafamily-body {
            > .ideafamily-idea {
              margin-left: $marginLinkbis/2 - 1;
              border-left: solid 1px #CCCCCC;
            }
        }
    }
}

.synthesis-idea:not(.canEdit) {
  padding-left: 3px;
  cursor: pointer;
  &:hover {
    background-color: $theme_synthesis_idea_hover_bg;
    .maxs, .icon {
      color: $idea_in_synthesis_icon_hover;
    }
    .comment-text {
      color: $idea_in_synthesis_comment_hover;
    }
  }
}

.is-selected > .synthesis-idea {
  background-color: $green1;
  border-right: 4px solid $green;
}


// The child list
// --------------
.ideafamily-children {
    height: 0px;
    overflow: hidden;
    display: none;

    @include transition( height 0.4s );
    @include transform( translateZ(0) );

    .ideafamily-item.is-open > .ideafamily-body > & {
        height: auto;
        display: block;
    }

    &:empty {
        display: none;
    }

}
