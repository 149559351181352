// The synthesis panel specific CSS
// ===========================

$idea_in_synthesis_no_hover: $txtGray;

.synthesis {
  position: relative;
  display: block;
  div {
    margin: 0;
  }

  .synthesis-expression {
    &.canEdit {
      border: 1px solid transparent;
      &:hover{
        -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px inset;
        -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px inset;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px inset;
        border: 1px solid #cccccc;
      }
    }
  }

  .comment {
    font-size: 11px;
    .synthesisIdea-replybox-openbtn {
      text-decoration: none;
      font-size: 20px;
      visibility: hidden;
      opacity: 0;
    }
    .authors {
      font-size: 11px;
      font-style: italic;
      text-decoration: none;
      cursor: default;
      color: inherit;
    }
  }

  .comment-info {
      margin-top: 6px;
      display: block;
      font-size: 12px;
      color: $idea_in_synthesis_no_hover;
    }
    .icon-comment {
      font-style: normal;
      font-size: 12px;
    }
}
