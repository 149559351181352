.capture {
  .popin {
    width: 450px;
  }
}

.web-capture {
  font-family: $baseFontFamily;
  font-size: $smallFontSize;
  p {
    margin: 10px 0 10px 0;
  }
}

.panel-body {
  top: 0;
}

.clipboard {
  .postitlist {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px dashed transparent;
  }
  &.is-dragover {
    .postitlist {
      border: 1px dashed #cccccc;
      @include border-radius(3px);
      min-height: 20px; //So we see the border when the list is empty
    }
  }
}

