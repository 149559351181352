// A message sending interface
// ===========================

.messageSend {
  margin: 0.5em 0 2.5em 0;
  display: block;
  background-color: $theme_messagesend_bg_color;
  padding: 10px 15px 5px;
  .hidden {
    display: none;
  }
  .avatar-container {
    padding: 0 0.5em 0 0;
    display: table-cell;
    width: 1px;
    vertical-align: top;
  }
  .messageSend-form {
    display: block;
    margin-left: 50px;
    .messageSend-subject,
    .messageSend-body {
      margin: 0;
      font-family: $baseFontFamily;
    }
    .topic-subject {
      display: table;
      width: 100%;
      margin-bottom: 0.5em;
      .topic-subject-label {
        display: table-cell;
        width: 1px;
        white-space: nowrap;
        vertical-align: middle;
      }
      .topic-subject-input {
        display: table-cell;
        //padding-left: 1em;
        input {
          width: 100%;
        }
      }
    }
    .messageSend-buttons {
      width: 50%;
      text-align: right;
      margin: 0;
      padding: 0;
      a {
        margin: 0.5em 0 0 0.5em;
      }
    }

  }
  .rc {
    border: none;
  }

}
