/**
 * Styleguide things are prefixed with `sg`
 */

.container {
    box-sizing: border-box;
    margin: auto;
    width: 1020px;

    .container {
        max-width: 100.1%;
    }

    @include medium-only {
        width: 724px;
    }
}

.row {
    @include clearfix();
    margin-left: -2.762430939%;
}

.col {
    @include col-span(12);
}

@for $i from 1 through 12 {
    // The column of the grid
    // ----------------------
    .col-#{$i} {
        @include col-span($i);
    }
}

// A section to a component
// =====================
.sg-section {
    padding: 20px;

    .sg-section-title {
        color: #484848;
        //border-bottom: 2px solid $gray4;
    }
}

.sg-sidebar {
    //background-color: $gray4;
    //position: fixed;
    top: 0;
    z-index: 1000;
    min-height: 100%;
    .logo {
        color: $purple2;
    }
}

.sg-main {
    margin-left: 20%;
    min-height: 100%;
    overflow: hidden;
}

.sg-title > a {
    color: #484848;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 6px;
    display: block;
    line-height: 1em;
    &:hover{
        background-color: #f2f2f2;
        text-decoration: none;
    }
}

//-----------
code[class*="language-"], pre[class*="language-"] {
    white-space: pre-wrap;
}

// styleguide components

.sg-body{
    background-color: $white;
}

.bx-color {
    .bg {
        height: 40px;
    }
    .code {
        width: 100px;
        height: 30px;
        color: $black;
    }
}

.icons-content {
    .bx {
        padding: 20px;
        margin-bottom: 20px;
        background: #ffffff;
        border-radius: 3px;
        i.icon {
            font-size: 1.5em;
            color: #404040;
        }
    }
}

.bs-example-popover {
    height: 400px;
    .popover {
        position: relative;
        display: block;
        float: left;
        width: 260px;
        margin: 20px;
    }
}

.bs-example {
    height: 50px;
}

table.table {
    width: 100%;
    padding: 0;
    margin-bottom: 1.4em;
    font-size: 12px;
    border: 1px solid #ddd;
    border-collapse: separate;
    border-radius: 4px;

    thead {
        th {
            padding-top: 9px;
            font-weight: bold;
            vertical-align: middle;
            border-bottom: 1px solid #ddd;
        }
        th + th, th + td, td + td {
            border-left: 1px solid #ddd;
        }

    }
    th, td {
        padding: 10px 10px 9px;
        line-height: 1.4em;
        text-align: left;
        vertical-align: top;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
}

//colors.  Mostly for styleguide, you should not normally use these in the DOM
.bg-purple {
    background-color: $purple;
}

.bg-purple1 {
    background-color: $purple1;
}

.bg-purple2 {
    background-color: $purple2;
}

.bg-purple3 {
    background-color: $purple3;
}

.bg-gray {
    background-color: $gray;
}

.bg-gray1 {
    background-color: $gray1;
}

.bg-gray2 {
    background-color: $gray2;
}

.bg-gray3 {
    background-color: $gray3;
}

.bg-gray4 {
    background-color: $gray4;
}

.bg-white {
    background-color: $white;
}

.bg-orange {
    background-color: $orange;
}

.bg-orange1 {
    background-color: $orange1;
}

.bg-orange2 {
    background-color: $orange2;
}

.bg-black {
    background-color: $black;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-blue {
    background-color: $blue;
}

.demo-popin {
    width: 500px;
    height: 350px;
}

.txt-white {
    color: white;
}

.txt-gray {
    color: $txtGray;
}

.txt-gray1 {
    color: $txtGray1;
    margin: 4px 0 4px 0px;
}

.txt-gray2 {
    color: $txtGray2;
    margin: 4px 0 4px 0px;
}

.txtPurple {
    color: $txtPurple;
}

.txtPurple1 {
    color: $txtPurple1;
}


#grid-content {
    .gr-zebra {
        .gu {
            &:nth-child(even) {
                background-color: $gray1;
            }
            &:nth-child(odd) {
                background-color: $gray3;
            }
        }
    }
}

//doc

.code-content {
    margin: 10px 0 0 0;
}

.typo-content, #text {
    pre {
        background: $white;
    }
}

pre {
    padding: 15px;
    margin: 0 0 20px 0;
    overflow: auto;
    background: #f5f2f0;
}

code[class*="language-"],
pre[class*="language-"] {
    white-space: pre-wrap;
}

/*
github.com style (c) Vasily Polovnyov <vast@whiteants.net>
https://github.com/isagalaev/highlight.js/tree/master/src/styles
*/

.hljs {
    display: block;
    padding: 0.5em;
    color: #333;
    background: #f8f8f8;
}

.hljs-comment,
.hljs-template_comment,
.diff .hljs-header,
.hljs-javadoc {
    font-style: italic;
    color: #998;
}

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.javascript .hljs-title,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
    font-weight: bold;
    color: #333;
}

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
    color: #099;
}

.hljs-string,
.hljs-tag .hljs-value,
.hljs-phpdoc,
.tex .hljs-formula {
    color: #d14;
}

.hljs-title,
.hljs-id,
.coffeescript .hljs-params,
.scss .hljs-preprocessor {
    font-weight: bold;
    color: #900;
}

.javascript .hljs-title,
.lisp .hljs-title,
.clojure .hljs-title,
.hljs-subst {
    font-weight: normal;
}

.hljs-class .hljs-title,
.haskell .hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
    font-weight: bold;
    color: #458;
}

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rules .hljs-property,
.django .hljs-tag .hljs-keyword {
    font-weight: normal;
    color: #000080;
}

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body {
    color: #008080;
}

.hljs-regexp {
    color: #009926;
}

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
    color: #990073;
}

.hljs-built_in,
.lisp .hljs-title,
.clojure .hljs-built_in {
    color: #0086b3;
}

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
    font-weight: bold;
    color: #999;
}

.hljs-deletion {
    background: #fdd;
}

.hljs-addition {
    background: #dfd;
}

.diff .hljs-change {
    background: #0086b3;
}

.hljs-chunk {
    color: #aaa;
}

.tooltip-container {
    //height: 50px;
    padding: 50px 0 50px 0;
}

.popover-container {
    height: 160px;
    line-height: 150px;
    box-sizing: border-box;
}
