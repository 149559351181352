.bookmarklet {
    border-radius: 4px;
    font-size: inherit;
    position: relative;
    cursor: move;
    color: #fff;
    text-decoration: none;
    background: rgba(0,0,0,0.25);
    padding: 4px;
    display: block;
}
