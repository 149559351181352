// Navigation bar

.cookie-banner{
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-bottom: 1px solid #CCC;
}

.navbar {
  @include no-selection;
  box-sizing: border-box;
  display: block;
  position: relative;
  z-index: 100; /* for dropdowns */
  background-color: $white;

  .navbar-left {
    height: $theme_header_height;
    float: left;
    position: relative;

    ul > li{
      list-style: none;
    }

    ul{
      .sub {
        height: $theme_header_height;
      }
    }

    .icon-infinite {
      position: absolute;
      top: -6px;
      color: $green;
    }
    .logo-container {
      height: $theme_header_height;
      max-width: 215px;
    }
    .socketIndicator {

    }

    .potential-discussion-dropdown-container {
      float: right;
      line-height: 45px;
      margin-left: 5px;
    }
  }

  .navbar-center {
    height: $theme_header_height;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: $theme_header_height;
    .add-group {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .navbar-right {
    height: $theme_header_height;
    position: absolute;
    right: 0;
    line-height: $theme_header_height;

    .help {
      font-size: 25px;
      color: $txtGray;
    }

    ul > li{
      list-style: none;
    }

    ul{
      .sub {
        height: $theme_header_height;
      }
    }

    .user-picture {
      display: inline-block;
    }

    .username, .user-lang {
      color: $theme_header_text_color;
      text-decoration: none;
    }

    .username:after {
      @extend %icon;
      content: '\e814';
      color: $theme_header_text_color;
    }

    .nav-notification {
      position: relative;
      display: none;
      .dropdown-arrow {
        color: $gray1;
        font-size: 150%;
      }
      .ui-label-content {
        position: absolute;
        top: -3px;
        right: -8px;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        .ui-label {
          padding: 0px 4px;
          font-size: 9px;
          box-sizing: border-box;
          height: 19px;
        }
      }
    }
    .joinDiscussion {

    }

    .sign-in-link {
      display: inline;
      vertical-align: middle;
      line-height: $theme_header_height;
      height: 100%;
    }
  }
}

.navbar-title {
  padding: 10px;
}

.infobar {
    position: relative;
    height: $infobar_height;
    font-size: 12px;
    text-align: center;
    box-sizing: border-box; /* include border in height */
    background-color: $theme_infobar_bg_color;
    border-top: 1px solid $theme_btn_primary_bg_color;
    .close {
      position: absolute;
      right: calc(12px - 0.2em);
      @include calc(top, "#{$infobar_height}/2 - 0.5em");
      color: $white;
      opacity: initial;
      cursor: pointer;
      font-size: 20px;
    }
    .content {
      margin: 0;
      padding: 0;
      color: $white;
      display: inline-block;
      height: 0; /* so that we can center it vertically without knowing its contents height */
      vertical-align: middle;
      font-weight: normal;

      a:not(.btn) {
        color: $white;
        text-decoration: underline;
      }
    }
  &.cookie-banner{
    line-height: 50px;
  }
  &.widget-banner{
    .session {
      border: none;
      background-color: $white;
      color: #AAAAAA;
      border-radius: 2px;
      padding: 6px;
      cursor: pointer;
    }
  }
}

.modal-joinDiscussion {
  text-align: center;
  .icon-discuss {
    color: $theme_text_icon_emphasis_color;
    font-size: 400%;
  }

  h1 {
    font-weight: bold;
  }

  ul {
    padding-left: 20px;
  }
}
