// Login box
// =========
.loginbox {
  box-sizing: border-box;
  @include border-radius(6px);
  margin: 5% auto 0 auto;
  width: 730px;
  background-color: #ffffff;
  box-shadow:0 0 225px rgba(255,255,255,.55);

  form {
    margin: 0 auto;

    a {
      color: $theme_activelink_color;
      font-size: 14px;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      text-decoration: underline;
    }
  }
  .inline {
    width: '';
    display:inline;
  }

  header {
    min-height: 60px;
    color:#CCCCCC;
    background-color: #484848;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;

    h1 {
      line-height: 35px;
      text-align: center;
      color: #fff;
      margin: 0;
      padding: 10px;
    }
  }

  .content {
    padding: 20px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .submitButtons {
    width: 100%;

    .centered {
      text-align: center;
    }
  }

  .login-box-title {
    text-align: left;
    font-size: 14px;
  }

  .register-box-title {
    padding-left: 175px;
    font-size: 20px;
  }

  .defaultfield {
    /* max-width: 290px; */
    width: 80%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
  }

  .signinWrapper, .signinSeparator, .socialPart {
    display: inline-block;
  }

  .signinWrapper {
    padding: 0 0 0 10px;
    box-sizing: border-box;
    vertical-align: top;
    width: 325px;

    form input.defaultfield, form.inline input {
      width: 100%;
    }

    .submitButtons td:nth-child(2) {
      text-align: right;
      a { /* align vertically with "Log in" button on the left */
        position: relative;
        top: -1px;
      }
    }

    .submitButtons input {
      width: auto;
    }
  }

  .socialPart {
    width: 320px;
    vertical-align: top;
  }

  .signinSeparator {
    margin: 50px 0 0 0;
    width: 35px;
    position:relative;

    .mask {
      overflow:hidden;
      width:20px;
      height:250px;
    }
    &.left .mask:after {
      content:'';
      display:block;
      margin-left:-20px;
      width:20px;
      height:100%;
      border-radius:12px / 125px;
      box-shadow:0 0 8px black;
    }

  }

  .spacer--big {
    clear: right;
  }
}

.loginbox.register {
  width: 400px;
  font-size: 13px;

  .content {
    padding: 10px 20px 10px;
  }

  .subcontent {
    margin: 17px 0 10px;
    text-align: center;
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }

    .btn-sm { /* use same text line and font size as the text on th eleft of the button */
      font-size: 13px;
      margin-top: -3px;
    }
  }

  .login-box-title {
    margin-left: 184px;
  }

  input {
    margin: 8px 0;
    width: 100%;
  }

  form {
    width: 250px;
    margin-top: 10px;
  }
}

.loginbox {
  &.request_password_change {
    padding-bottom: 10px;

    input {
      margin: 8px 0;
      width: 100%;
    }

    .error {
      margin: 20px;
      // color: red;
      font-size: medium;
      text-align: center;
    }

    form {
      width: 250px;
      margin-top: 20px;
    }
  }
}
