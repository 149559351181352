$panelarea-min-width: 420px;
$panelarea-min-height: 400px;

// The wrapper for all .panel
// =================
.panelarea {
  position: absolute;
  top: 36px;
  right: 0;
  bottom: 4px;
  left: 0;
  min-height: $panelarea-min-height;
  display: block;
  overflow: {
    x: auto;
    y: hidden;
  }
  white-space: nowrap;
}

.discussionlist {
  width: 1200px;
  margin: 0 auto;
  .bx {
    width: 220px;
    height: 150px;
    margin: 10px;
    text-align: center;
    box-sizing: border-box;
    .discussion {
      a {
        text-decoration: none;
        color: #000000;
      }
    }
  }
}
