/* Generic */
.core-form .social-bg,
.social-bg {
  font-family: $baseFontFamily;
  display: block;
  height: 37px;
  width: 240px;
  color: $white;
  font-size: 14px;
  border: none;
  padding: 0px;
  line-height: 37px;
  text-align: center;
  @include border-radius(3px);
  text-decoration: none;

  &:before{
    @extend %icon;
    color: $white;
    font-size: 18px;
    line-height: 37px;
    width: 40px;
    height: 37px;
    margin: -1px -1px 0 -2px;
    float: left;
    @include border-radius(3px 0 0 3px);
  }
}


/* Facebook */
.core-form .facebook-bg,
.facebook-bg {
  background-color: #3b5998;

  &:before{
    content: '\e829';
    background-color: #354f88;
  }
}

.facebook-bg:hover {
  cursor: pointer;
}

.facebook-bg:active {
  background: #354f88;
}

/* Twitter */
.core-form .twitter-bg,
.twitter-bg {
  background-color: #45b0e3;

  &:before{
    content: '\e82a';
    background-color: #40a2d1;
  }
}

.twitter-bg:hover {
  cursor: pointer;
}

.twitter-bg:active {
  background: #40a2d1;
}

/* Google+ */
.core-form .google-oauth2-bg,
.google-oauth2-bg {
  background-color: #de4c34;

  &:before{
    content: '\e82b';
    background-color: #ce3e26;
  }
}

.google-oauth2-bg:hover {
  cursor: pointer;
}

.google-oauth2-bg:active {
  background: #ce3e26;
}


/* Yammer */
.core-form .yammer-bg,
.yammer-bg {
  background-color: #0086e6;

  &:before{
    content: '\e83a';
    background-color: #0072c6;
  }
}

.yammer-bg:hover {
  cursor: pointer;
}

.yammer-bg:active {
  background: #0095ff;
}


/* LinkedIn */
.core-form .linkedin-bg,
.linkedin-oauth2-bg {
  background-color: #0077b5;

  &:before{
    content: '\e82d';
    background-color: #0072c6;
  }
}

.linkedin-oauth2-bg:hover {
  cursor: pointer;
}

.linkedin-oauth2-bg:active {
  background: #0095ff;
}


/* OpenID */
.openid-icon-bg:after {
  @extend %icon;
  content: '\e82e';
  display: block;
  padding: 11px 10px 6px 13px;
  color: $white;
  font-size: 18px;
}

.openid-icon-bg {
  background: $orange2;
  margin: 0 0 -37px 0;
  width: 41px;
}

.social-bg.openid-bg {
  height: 33px;
  margin-left: 41px;
  width: 216px;
  color: $gray2;
  border: 1px solid $gray3;
}