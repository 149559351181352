.messageList-list {
  display: block;
  //border: 1px solid red;
  margin-left: auto;
  margin-right: auto;
  #messageList.is-message-mode & { // TODO: #messageList is not used anymore. Decide if this should be removed or moved to .messageList
    display: none;
  }
}

#messageList-messages {
  display: none;

  #messageList.is-message-mode & { // TODO: #messageList is not used anymore. Decide if this should be removed or moved to .messageList
    display: block;
  }
}

.messageList {
  position: relative;

  .messageList-query-info {
    white-space: normal;
    text-align: center;

  }
  .panel-body {
    top: 0px;
    background-color: $theme_messagelist_bg_color;
    z-index: 1;
  }

  .options-filter,
  .view-config {
    box-sizing: border-box;
    height: auto;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    background-color: $white;
    .config {
      > li {
        list-style: none;
        a {
          text-decoration: none;
          color: $black;
        }
      }
    }
  }

  .view-config {
    @include display(flex);
    @include flex-direction(row);
    justify-content: space-between;
    -ms-flex-pack: space-between; // IE 10 way of saying "justify-content: space-between;"
    .config {
        @include flex(1);
    }
  }

  .show-expert-mode {
    @include display(flex);
    @include flex-direction(row);

    .show-expert-mode-label {
      line-height: 24px; // the height of the toggle button
      min-width: 85px; // the approximate width of label's text
      padding-left: 15px;
      padding-right: 2px;
      text-align: right;
      vertical-align: middle;
      color: $txtGray1;
    }

    .show-expert-mode-toggle-button {
      margin-top: 3px;
    }
  }

  .options-filter {
    max-height: 60px;
    @include display(flex);
    @include flex-direction(row);

    .options-filter-items {
        @include display(flex);
        @include flex-direction(row);
        @include flex(1);
    }

    .option {
      box-sizing: border-box;
      //width: 50%;
      min-height: 36px;
      @include flex-grow(1);
      cursor: pointer;
      color: $theme_inactivelink_color;
      background-color: $white;
      @include transition(border-bottom 0.2s ease-in);
      border-bottom: 3px solid transparent;
      &:hover {
        border-bottom: 3px solid $theme_inactivelink_color;
      }
    }
    .option:first-child {
      //border-right: 1px solid $gray;
    }
    .option.selected {
      color: $theme_activelinkFilter_color;
      font-weight: 600;
      border-bottom: 3px solid $theme_activelinkFilter_color;
    }

    .show-expert-mode {
      padding-top: 5px;
    }
  }

  .sticky-box {
    height: 55px;
    box-shadow: 0px -3px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: rgba($white, 0.65);
    position: absolute;
    bottom: 10px; //Leave some space for the horizontal scrollbar
    width: 100%;
    left: 0;
    line-height: 37px;
    z-index: 2;

    .icon-comment {
      font-size: 22px;
      vertical-align: sub;
      line-height: 0;
      margin: 0 !important;
      padding: 0 !important;
    }
    .icon-comment::before {
      margin-left: -2px !important;
    }

  }

  .real-time-updates{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 2;

    .btn-real-time{
      box-shadow: 0 3px 8px rgba(0,0,0,0.5);
      height: 24px;
      cursor: pointer;
      @include border-radius(0 0 2px 2px);
      font-weight: bold;
      font-size: 12px;
      border: none;
    }
  }

}
