html, body {
  font-family: $baseFontFamily;
  font-size: $smallFontSize;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: $baseLineHeightFontMultiplier;
}

body{
  background-color: $gray3;
}

@include selection {
  background: $textSelectionBackgroundColor;
}

@include scrollbar {
  background-color: transparent;
  width: 9px;
  height: 10px
}

@include scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 3px;
  width: 10px;
  min-height: 40px;
}

@include placeholder {
  color: transparentize($gray1, 0.3);
}

.no-fx {
  @include transition(none !important);
}

.user-select-none {
  @include user-select(none);
  &::selection {
    background: transparent;
  }
  &::-moz-selection {
    background: transparent;
  }
}

.cursor-nodrop {
  cursor: no-drop !important;
}

.clearfix {
  @include clearfix;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.error-message {
  // color: $red;
}

/* only useful for the "auto" or "append" version of the oembed library
.oembedall-closehide {
  padding: 0 4px;
}
*/

#popover-oembed {
  z-index: 101; /* has to be superior to the z-index of .popin-wrapper (defined in assembl/static/css/components/_modal.scss) */
  background-color: white;
  border: 1px solid $theme_box_border_color;
  min-width: 50px;
  min-height: 50px;
  max-width: 90%;
  max-height: 90%;
}
