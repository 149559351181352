// Avatars

.agentAvatar {
  float: left;
  display: inline-block;
  margin-right: 5px;
}
.avatar {
  display: block;
  padding: 1px;

  img {
    display: inline-block;
    border-radius: $borderRadiusMS;
    opacity: 1;
    @include transition(opacity 0.2s);
    overflow: hidden;
  }
  

  &:hover,
  &:focus {
    img {
      opacity: 0.8;
    }
  }

  &.img-rounded {
    padding: 0;

    img {
      border-radius: 50%;
      //border: 3px solid $blue2;
    }
  }
}

a:hover, a:focus {
  .avatar {
    img {
      opacity: 0.8;
    }
  }
}
