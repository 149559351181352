
// rule filter to block browsers that don't support that selector from applying rules they shouldn't
.custom-checkbox:not(#unsupported) {
  cursor: pointer;

  input[type=checkbox] {
    opacity: 0;

    &:focus + .custom-checkbox-element {
      // if behavior necessary
    }

    &:checked + .custom-checkbox-element {
      @extend %icon;
      @extend .icon-check;
      color: $theme_checkbox_color;
    }

  }

  .custom-checkbox-element {
    @extend %icon;
    @extend .icon-check-empty;
    display: inline-block;
    width: 17px;
    height: 17px;
    line-height: 16px;
    margin: 0 0 2px 0;
    margin-right: $spacingXs;
    margin-left: -20px;
    border-radius: 2px;
    vertical-align: middle;
    text-align: center;
    color: #cccccc;
    font-size: 20px;
  }

  &:hover {
    /*.custom-checkbox-element {
      border-color: mix(#000000, $inputBorder, 25%);
    }*/
  }

  input:checked + .custom-checkbox-element {
    &:after {
      visibility: visible;
    }
  }
}

.custom-radio:not(#unsupported) {
    cursor: pointer;

    input[type=radio] {
        opacity: 0;

        &:focus + .custom-radio-element {
            // if behavior necessary
        }

        &:checked + .custom-radio-element {
            @extend %icon;
            @extend .icon-dot-circled;
            color: $theme_checkbox_color;
        }

    }

    .custom-radio-element {
        @extend %icon;
        @extend .icon-circle-empty;
        display: inline-block;
        width: 17px;
        height: 17px;
        line-height: 16px;
        margin: 0 0 2px 0;
        margin-right: $spacingXs;
        margin-left: -20px;
        border-radius: 2px;
        vertical-align: middle;
        text-align: center;
        color: #cccccc;
        font-size: 20px;
    }

    &:hover {
        /*.custom-checkbox-element {
          border-color: mix(#000000, $inputBorder, 25%);
        }*/
    }

    input:checked + .custom-radio-element {
        &:after {
            visibility: visible;
        }
    }
}
