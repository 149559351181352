/* 
  ASSEMBL DEFAULT THEME
  If you want to create a new theme, it is recommended that your theme SCSS file uses @import of this file, so if a default theme variable is not overriden in your theme (for example a variable which would have been added in Assembl code after the creation of your theme), the SCSS will still compile, and the variable will have the default value.
 */

/*
  Variables
  Ranked from light -> dark

  NEVER refer to these color variables elsewhere in the SCSS files
*/
$th_local_white: #FFFFFF;
$th_local_black: #000000;

// Browns
$th_local_brown1: #fefdfc; // $theme_navigation_bg, $theme_navigation_menu_separator
$th_local_brown2: #aa9886; // $theme_idea_header_bg
$th_local_brown3: #9a8976; //$theme_navigation_menu_bg_active, $theme_panel_selected_color, $theme_synthesis_header_bg
$th_local_brown4: #68503e; // $theme_header_panel, $theme_navigation_menu_bg
$th_local_brown_01: #514438; // unused
$th_local_brown_02: #e0a870; // unused

$th_local_grey1: #F6F6F6; // white. $theme_navigation_or_idea_bg_active, $theme_messagesend_bg_color
$th_local_grey2: #ecf0f1; // almost white (cyan) $theme_messagelist_bg_color, $theme_panel_group_header_bg_color
$th_local_grey3: #CCCCCC; // $theme_box_border_color, theme_navigation_idea_total_count
$th_local_grey4: #999999; // $theme_inactivelink_color, theme_navigation_options, theme_navigation_idea_header_text_color
$th_local_grey5: #7F8C8D; // grey, slightly cyan. $theme_btn_cancel_text_color
$th_local_grey6: #484848; // $theme_header_text_color, theme_navigation_idea_hover_unread_count, theme_navigation_selected_idea_unread_count, theme_message_reply_btn_color
$th_local_grey_01: #EEEEEE; // almost white. unused
$th_local_grey_02: #888888; // like th_local_grey5, less cyan. unused.

$th_local_green3: #2f8b51;
/*
$theme_checkbox_color, $theme_form_field_active_border_color, $theme_btn_primary_bg_color
$theme_btn_secondary_border_color, $theme_popin_header_bg,
$theme_read_unread_item, $theme_announcement_box_border_color,
$theme_infobar_bg_color, $theme_item_selected_secondary_color, $theme_message_icon_color
*/
$th_local_green1: lighten($th_local_green3, 50%); // #cbeed8 $theme_item_selected_color
$th_local_green2: lighten($th_local_green3, 10%); // #59c782 $theme_text_icon_emphasis_color 3cb167
$th_local_green4: #1c6336;
/*
$theme_activelink_color, $theme_activelink_color, $theme_btn_secondary_text_color,
$theme_btn_default_text_color, $theme_header_debate_title_color
 */
$th_local_green_01:  #4ac780; // unused
$th_local_green_02: #37A55E; // unused
$th_local_green_03: #CCEFDB; // almost white. unused

//Global to the entire scss infrastructure, mandatory variables for EVERY theme
$theme_activelink_color: $th_local_green4;
$theme_activelinkFilter_color: $th_local_green4;
$theme_inactivelink_color: $th_local_grey4;
$theme_checkbox_color: $th_local_green3;
$theme_form_field_active_border_color: $th_local_green3;

$theme_person_name_color: $th_local_green4;

$theme_btn_primary_text_color: $th_local_white;
$theme_btn_primary_bg_color: $th_local_green3;

$theme_btn_secondary_text_color: $th_local_green4;
$theme_btn_secondary_bg_color: $th_local_white;
$theme_btn_secondary_border_color: $th_local_green3;

$theme_btn_cancel_text_color: $th_local_grey5;
$theme_btn_default_text_color: $th_local_green4;

$theme_popin_header_bg: $th_local_green3;
$theme_popin_header_text_color: $th_local_white;

$theme_header_panel: $th_local_brown4;

$theme_read_unread_item: $th_local_green3;

//Not currently used
//$theme_header_separator: $th_local_brown1;
$theme_header_panel_text_color: $th_local_white;
$theme_header_panel_icon_color: $th_local_white;

$theme_announcement_box_border_color: $th_local_green3;
$theme_box_border_color: $th_local_grey3;

//Header
$theme_header_debate_title_color: $th_local_green4;
$theme_header_height: 40px;
$theme_header_text_color: $th_local_grey6;
$theme_header_bg_color: $th_local_white;
$theme_header_logo: '../default/img/logo.png';

//Info bar
$theme_infobar_bg_color: $th_local_green3;

//Context
$theme_context_bg: '../default/img/crowd2.jpg';
$theme_context_page_header_height: 227px;
$theme_context_title_color: $th_local_white;

//Navigation
$theme_navigation_bg: $th_local_brown1; // background color of the content of the navigation menu (aka the section contained in the currently selected navigation item, which shows below its title, as it's an accordion menu)
$theme_navigation_menu_bg: $th_local_brown4; // background color of a non-selected item of the navigation menu
$theme_navigation_menu_bg_active: $th_local_brown3;
$theme_navigation_menu_bg_hover: $theme_navigation_menu_bg_active;
$theme_navigation_menu_separator: $th_local_brown1;
$theme_navigation_icon_color: $th_local_white;
$theme_navigation_icon_color_active: $th_local_white;
$theme_navigation_text_color: $th_local_white;
$theme_navigation_text_color_active: $th_local_white;
$theme_navigation_options: $th_local_grey4;
$theme_navigation_idea_text_color: $th_local_black;
$theme_navigation_idea_header_text_color: $th_local_grey4;
$theme_navigation_idea_total_count: $th_local_grey3;
$theme_navigation_idea_hover_text_color: $theme_navigation_idea_text_color;
$theme_navigation_idea_hover_total_count: $theme_navigation_idea_total_count;
$theme_navigation_idea_hover_unread_count: $th_local_grey6;
$theme_navigation_idea_hover_unread_count_background: $th_local_white;
$theme_navigation_selected_idea_text_color: $theme_navigation_idea_text_color;
$theme_navigation_selected_idea_total_count: $theme_navigation_idea_total_count;
$theme_navigation_selected_idea_unread_count: $th_local_grey6;
$theme_navigation_selected_idea_unread_count_background: $th_local_white;
$theme_navigation_idea_unread_count: $theme_read_unread_item;

//Navigation + Synthesis panel
$theme_navigation_or_idea_bg_active: $th_local_grey1; // in the navigation menu, background color of a hovered idea or a hovered synthesis 
$theme_item_selected_color: $th_local_green1; // in the navigation menu, background color of a selected idea or a selected synthesis 
// Used for the vertical bar to give additional emphasis un the table of idea and in the list of syntheses
$theme_item_selected_secondary_color: $th_local_green3; // in the navigation menu, background color of a 4px-thick right border on a selected idea or a selected synthesis 

//Message list
$theme_messagelist_bg_color: $th_local_grey2;
$theme_messagesend_bg_color: $th_local_grey1;
//The color of the icons of the action buttons on messages
$theme_message_icon_color: $th_local_green3;
$theme_message_highlight_background: $theme_item_selected_color; // in a message, background color of an extract (extracts are harvested sections of a message, which are highlighted in this color)
$theme_message_reply_btn_color:$th_local_grey6;

//Panel groups
//$theme_panel_group_header_bg_color: $th_local_grey_01;
$theme_panel_group_header_bg_color: $th_local_grey2;
$theme_panel_group_header_close_color: $theme_header_text_color;
$theme_text_icon_emphasis_color: $th_local_green2;
$theme_panel_selected_color: $th_local_brown3;

//extract
$theme_extract_bg: $theme_navigation_bg;

//idea panel
$theme_idea_header_bg: $th_local_brown2;

// Synthesis message
$theme_synthesis_header_bg: $th_local_brown3;
$theme_synthesis_idea_hover_bg: $th_local_grey1;


/* Theme standard CSS definitions (mainly logos and images).
   Maybe we don't need this at all, and could    use variables only */
//TODO
