// blocked and with a loading message
// ****************
.loader-wrapper {
  position: relative;
  min-height: 16px; /* the size of the loader image */
  height: 100%;
  width: 100%;
}
.is-loading {
  cursor: wait;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include z-index("float");

    display: block;
    content: "";
    background: transparent url(#{$img-dir}/panel/loading.gif) no-repeat 50% 50%;
  }
}
