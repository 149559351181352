// The list of Ideas
// =================

.idealist-header {
  min-height: 13px;
}

.idealist-header-title {
  display: inline-block;
  color: $theme_navigation_idea_header_text_color;
  text-transform: uppercase;
  font-size: 11px;
}

.ideaList .idealist-actions {
  display: inline-block;
  position: absolute;
  right: 6px;

  .idealist-add-idea-button {
    display: inline-block;
  }

  .dropdown.is-open .dropdown-menu {
    max-width: 50vw;
    li a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .dropdown .icon-options {
    color: $theme_navigation_options;
  }
}


.idealist-actions .dropdown-menu {
  left: auto;
  right: 0;
}

// A row within the list of ideas
// -----------------
.idealist-item {
  line-height: $tableOfIdeasRowHeight;
  &.hidden {
    display: none;
  }
}

// idea list open ( showing the children )
// *********************
.idealist-item.is-open {
}

// When there is something being dragged over
// *********************
.idealist-item.is-dragover {
}

// When there is something being dragged in above area
// *********************
.idealist-item.is-dragover-above {
}

// When there is something being dragged in below area
// *********************
.idealist-item.is-dragover-below {
}

// When it is selected
// *********************
.idealist-item.is-selected {
}

// Where the content is placed
// --------------
.idealist-body {
  position: relative;
  //border-bottom: $idealist-border;
  clear: both;

  &.is-hovered {
    background-color: $theme_navigation_or_idea_bg_active;
    //border-right: 4px solid $green;
    .idealist-title {
      color: $theme_navigation_idea_hover_text_color;
    }
    .idealist-counter {
      color: $theme_navigation_idea_hover_total_count;
    }
    .idealist-title-unread-count {
      //find the best solution for radius hover
      color: $theme_navigation_idea_hover_unread_count;
      background-color: $theme_navigation_idea_hover_unread_count_background;
      @include border-radius(10px);
      padding: 2px 6px;
    }
  }

  &:first-child {
    border-top: 0;
  }

  .idealist-item.is-dragover-above > &,
  .idealist-item.is-dragover > &,
  .idealist-item.is-dragover-below > & {
    border-color: transparent !important;

    .idealist-abovedropzone {
      border-top: solid 1px $orange !important;
    }
    .idealist-dropzone {
      border-color: $orange !important;
    }
  }

  .idealist-item.is-dragover:not(.is-dragover-below) > & {
    background-color: $yellow;
  }

  .idealist-item.is-selected > & {
    .idealist-title {
      color: $theme_navigation_selected_idea_text_color;
    }
    background-color: $theme_item_selected_color;
    border-right: 4px solid $theme_item_selected_secondary_color;
    .idealist-counter {
      color: $theme_navigation_selected_idea_total_count;
    }
    .idealist-title-unread-count {
      color: $theme_navigation_selected_idea_unread_count;
      background-color: $theme_navigation_selected_idea_unread_count_background;
      padding: 2px 6px;
      font-size: 11px;
    }
  }

}

/* This will show/hide the checkboxes next to each idea of the tables of ideas when a synthesis creation panel is present/absent. */
#groupContainer .idealist-body .custom-checkbox {
  display: none;
}

#groupContainer.hasSynthesisPanel .idealist-body .custom-checkbox {
  display: block;
}



// For an root idea
.idealist-bottom.idealist-body--rootidea,
.idealist-bottom.idealist-body--synthesis,
.idealist-bottom.idealist-body--others {
  font-size: 12px;
  line-height: 20px;
  .idealist-counter {
    padding: 0;
    min-width: 0;
    float: none;
    color: inherit;
    font-weight: normal;
  }
  .idealist-title {
    &:hover {
      color: $orange2;
      background-color: none;
      i.icon-arrowright {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  i.icon-arrowright {
    visibility: hidden;
    opacity: 0;
  }
}

// Area where to drop and create a sibling
// --------------
.idealist-abovedropzone {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  left: 0;

  height: 10px;

  .idealist-item.is-dragover-above > .idealist-body & {
    background-color: $yellow;
  }

}

// Area where to drop and create a child
// --------------
.idealist-dropzone {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: -1px;
  left: 0;

  height: 10px;
  //border-bottom: $idealist-border;

  .idealist-item.is-dragover-below > .idealist-body & {
    background-color: $yellow;
  }

}

// Subtitle ( which goes AFTER the title visually )
// TODO: This seems to be used nowhere except in old styleguide. Remove?
// ----------------
.idealist-subtitle {
  float: right;
  color: $gray1;
  margin: 0 10px;
}

// The title
// ----------
.idealist-title {
  display: block;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  //padding-left: 10px;
  color: $theme_navigation_idea_text_color;
  line-height: $tableOfIdeasRowHeight - 2;
}

.idealist-title-unread {
  font-weight: 600;
}

.idealist-title-unread-count {
  font-size: 11px;
  color: $theme_navigation_idea_unread_count;
  @include border-radius(10px);
  padding: 2px 6px;
}

// The counter at the right
// ------------------
.idealist-counter {
  float: right;
  padding: 0 12px 0 0;
  min-width: 30px;
  text-align: right;
  font-size: 11px;
  font-weight: bold;
  color: $theme_navigation_idea_total_count;
}

// Remove button
// --------------
.idealist-removebtn {
  @extend .idealist-counter;
  font-size: 18px;
  cursor: pointer;
}

// The arrow
// ------------
.idealist-arrow {
  background-image: url(#{$img-dir}/idealist/idealist-arrow-closed.png);
  background-position: center center;
  background-repeat: no-repeat;
  &.level1 {
    background-image: url(#{$img-dir}/idealist/idealist-arrow-closed-l1.png);
  }
  &:hover, &.level1:hover {
    background-color: $theme_navigation_or_idea_bg_active;
  }

  width: 30px;
  height: $tableOfIdeasRowHeight;
  display: block;
  float: left;
  cursor: pointer;

  // @include transition( transform 0.4s );
  // @include transform( rotate(-90deg) );
  // opacity: 0.55;

  // &:hover {
  //     opacity: 1;
  // }

  .idealist-item.is-open > .idealist-body & {
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(#{$img-dir}/idealist/idealist-arrow-open.png);
    &.level1 {
      background-image: url(#{$img-dir}/idealist/idealist-arrow-open-l1.png);
    }
    // @include transform( rotate(0deg) );
  }
}

// Reserves the space if there are no children to maintain indentation
.idealist-noarrow {
  background: url(#{$img-dir}/idealist/idealist-noarrow.png) center center no-repeat;
  width: 30px;
  height: $tableOfIdeasRowHeight;
  display: block;
  float: left;
}

// An empty space for indentation
// --------------
.idealist-space {
  display: block;
  width: 30px;
  height: $tableOfIdeasRowHeight;
  float: left;
}

// The bar to make an .idealist-item looks like a child
// --------------
.idealist-bar {
  background: url(#{$img-dir}/idealist/idealist-bar.png) center center no-repeat;
  display: block;
  width: 30px;
  height: $tableOfIdeasRowHeight;
  float: left;
}

// The link between an idealist and its parent
// --------------
.idealist-link, .idealist-link-last {
  display: block;
  width: 30px;
  height: $tableOfIdeasRowHeight;
  float: left;
}
.idealist-link {
  background: url(#{$img-dir}/idealist/idealist-link.png) center center no-repeat;
}

.idealist-link-last {
  background: url(#{$img-dir}/idealist/idealist-link-last.png) center center no-repeat;
}

// The child list
// --------------
.idealist-children {
  height: 0px;
  overflow: hidden;
  display: none;
  font-size: 98.5%; // so idea titles will be smaller as their depth increase

  @include transition(height 0.4s);
  @include transform(translateZ(0));

  .idealist-item.is-open > & {
    height: auto;
    display: inline; // initial;
  }

  &:empty {
    display: none;
  }

}

#idealist-list {
  .custom-checkbox {
    height: $tableOfIdeasRowHeight;
    width: $tableOfIdeasRowHeight;
    line-height: $tableOfIdeasRowHeight - 2;
    float: left;
    &:hover {
      background-color: $theme_navigation_or_idea_bg_active;
    }
  }
}
