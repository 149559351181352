// DELETE THIS FILE AFTER REFACTORING

// Link component
// ==============
.link {
  cursor: pointer;
  color: #484848;
  text-decoration: none;
  &:hover {
    color: $gray3;
  }

  &:active {
    color: $gray1;
  }
}

// Segment link
// ============
.segment-link {
  @extend .link;
}
