.warning-box, .warning-box-for-message-post {
  background-color: #f4eadc;
  padding: 3px 10px;
  margin: 10px 0;
  border-left: 3px solid #f0ad4e;
  box-sizing: border-box;
  p {
    margin: 0;
    text-align: left;
  }
}

.warning-box-for-message-post {
  border-left: 3px solid $theme_activelink_color;
  background-color: $white;
  padding: 0 10px;
}

//---------------------- New design box ----------------------//

.bx {
  padding: $spacingM;
  border: 1px solid $theme_box_border_color;
  border-radius: $borderRadiusS;
  background: $white;
  box-shadow: 1px 1px 4px $theme_box_border_color;
  margin-bottom: $spacingL;

  .bx-close {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0px;
    z-index: 1; //Close button should always be on top
    height: auto;
    font-size: 14px;
    line-height: normal;
    color: darken($theme_box_border_color, 30%);
    opacity: 0.4;
  }

  &.bx-default {
    //border: 1px solid $theme_box_border_color;
    box-shadow: none;
  }

}

.bx-empty {
  border: 2px dashed lighten($theme_box_border_color, 80%);
  //background: lighten($theme_box_border_color, 94%);
  box-shadow: none;
  color: lighten($theme_box_border_color, 40%);

  .h-bx {
    border-bottom-color: lighten($theme_box_border_color, 83%);
    color: lighten($theme_box_border_color, 40%);
  }

  &:hover {
    background: lighten($yellow, 40%);
  }
}

.bx-alert-warning {
  background-color: $orange;
  color: mix(#ffffff, $theme_box_border_color, 15%);

  .h-bx {
    border-bottom-color: $orange1;
  }
  a {
    color: $purple1;
  }

  .bx-alert-icon {
    background: none;
    color: $yellow;
  }
}

.bx-alert-success {
  background-color: #4bad00;
  color: $white;

  .bx-close {
    background: transparent;
  }
}

.bx-alert-error {
  background-color: #d54243;
  color: $white;

  .bx-close {
    background: transparent;
  }
}
