%button {
  @include no-selection;
  cursor: pointer;
  display: inline-block;
}

// Default button
// ==============
.button {
  @extend %button;
  box-sizing: border-box;
  @include border-radius(5px);
  padding: {
    right: 6px;
    left: 6px;
  }
  line-height: 25px;
  background-color: #ebebeb;
  font-size: 13px;
  text-align: center;
  color: #535353;
}

// Reduce the size of the button
// ++++++++++++++++++++++
.button.button--condensed {
  padding: {
    right: 4px;
    left: 4px;
  }
  line-height: 26px;
}

// Default button hover state
// **************************
.button:hover {
  background-color: #dedede;
  text-decoration: none;
}

// Disabled button
// ***************
.button[disabled],
.button.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;

  background-color: #ebebeb !important;
}

// Default button active state
// *******************
.button:active {
  background-color: #d2d2d2;
}

// Create a button group
// ===============
.buttongroup {
  font-size: 0;
  display: inline-block;

  .button {
    border-radius: 0;
    font-size: 13px;
  }

  .button:first-child {
    border-radius: 5px 0 0 5px;
    border-right: 0;
  }

  .button:last-child {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}

// Iconed button
// =============
.iconbutton {
  @extend %button;
  @include no-selection;
  position: relative;
  display: inline-block;
  line-height: 32px;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
  opacity: 0.55;
  color: $white;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  &:active {
    @extend .iconbutton.is-activated;
  }
}

// Active state
// ************
.iconbutton.is-activated {
  opacity: 1;
  background-color: transparentize(#e5e5e5, 0.5);
}

// DELETE OVER THIS LINE AFTER REFACTORING

/* New design button */

.btn {
  @include border-radius(3px);
  display: inline-block;
  line-height: 1.42857143;
  margin: 0px 0px 0px 0px;
  font-weight: 600;
  font-family: $baseFontFamily;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: $baseFontSize;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/************************/

.btn[disabled],
.btn.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-cancel {
  color: $theme_btn_cancel_text_color;
  background-color: transparent;
}

.btn-cancel:hover {
  text-decoration: underline;
}

.btn-cancel:focus {
  outline: 0;
}

.btn-cancel:active,
.btn-cancel.active {
  opacity: 0.8;
  outline: 0;
}

/**********************/

.btn-purple {
  margin: 4px 4px 4px 4px;
}

.btn-default, .btn-bright {
  color: $theme_btn_default_text_color;
  background-color: transparent;
  padding: 0px; //To cancel btn padding
  margin: 0 6px 0 0;
  &.btn-sm, &.btn-xs, &.btn-large{
    padding: 0px;
  }
}

.btn-default:hover, .btn-bright:hover {
  //text-decoration: none;
}

.btn-default:focus, .btn-bright:focus {
  outline: 0;
}

.btn-default:active,
.btn-default.active,
.btn-bright:active,
.btn-bright.active {
  opacity: 0.8;
  outline: 0;
}

/*********************/
.btn-primary {
  color: $theme_btn_primary_text_color;
  background-color: $theme_btn_primary_bg_color;
}

.btn-primary:hover {
  opacity: 0.8;
  text-decoration: none;
}

.btn-primary:focus {
  outline: 0;
}

.btn-primary:active,
.btn-primary.active {
  opacity: 1;
  outline: 0;
}

/*********************/
.btn-secondary {
  color: $theme_btn_secondary_text_color;
  background-color: $theme_btn_secondary_bg_color;
  border: 1px solid $theme_btn_secondary_border_color;
  margin: 0 6px 0 0;
}

.btn-secondary:hover {
  color: $theme_btn_secondary_bg_color;
  background-color: $theme_btn_secondary_text_color;
  text-decoration: none;
}

.btn-secondary:focus {
  outline: 0;
}

.btn-secondary:active,
.btn-secondary.active {
  opacity: 0.8;
  background-color: $theme_btn_secondary_text_color;
  color: $theme_btn_secondary_bg_color;
  outline: 0;
}

.btn-sm {
  padding: 5px 10px;
  font-size: $smallFontSize;
  line-height: 1.5;
  border-radius: 3px;
  height:30px;
}

.btn-xs {
  padding: 1px 5px;
  font-size: $smallFontSize;
  line-height: 1.5;
  border-radius: 3px;
}

/*********************/
.btn-third {
  color: $theme_btn_secondary_text_color;
  background-color: $theme_btn_secondary_bg_color;
  border: 1px solid $theme_btn_secondary_border_color;
}

.btn-third:hover {
  opacity: 0.8;
  text-decoration: none;
}

.btn-third:focus {
  outline: 0;
}

.btn-third:active,
.btn-third.active {
  opacity: 1;
  outline: 0;
}
