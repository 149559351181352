
// Adds a gray dot before the word
// ========
#onlinedot {
    @include display(flex);
    @include flex-direction(row);

    .dot-offline {
      display: block;
      line-height: $theme_header_height;
    }

    .dot-online {
        display: none;
    }
}


// Online version
// **********
#onlinedot.is-online {

    &:before {
        background-color: #13a89e;
    }

    .dot-offline {
        display: none;
    }

    .dot-online {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: $theme_header_height;
    }

}
