.rc, .richcontainer {
  display: block;
  @include clearfixHack();

  .bd {
    display: table-cell;
    *display: inline-block;
    zoom: 1;

    &:after {
      clear: both;
      display: block;
      visibility: hidden;
      overflow: hidden;
      height: 0 !important;
      line-height: 0;
      font-size: xx-large;
      content: " x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x ";
    }
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
  .img, .img-left {
    float: left;
  }
  .img-right, .imgRight {
    float: right;
  }
}

.rcsxs .img, .rcsxs .img-left {
  margin-right: $spacingXs;
}

.rcss .img, .small .img, .rcss .img-left, .small .img-left {
  margin-right: $spacingS;
}

.rcsm .img, .medium .img, .rcsm .img-left, .medium .img-left {
  margin-right: $spacingM;
}

.rcsl .img, .large .img, .rcsl .img-left, .large .img-left {
  margin-right: $spacingL;
}

.rcsxl .img, .xlarge .img, .rcsxl .img-left, .xlarge .img-left {
  margin-right: $spacingXl;
}

.rcsxs .img-right, .rcsxs .imgRight {
  margin-left: $spacingXs;
}

.rcss .img-right, .small .img-right, .rcss .imgRight, .small .imgRight {
  margin-left: $spacingS;
}

.rcsm .img-right, .medium .img-right, .rcsm .imgRight, .medium .imgRight {
  margin-left: $spacingM;
}

.rcsl .img-right, .large .img-right, .rcsl .imgRight, .large .imgRight {
  margin-left: $spacingL;
}

.rcsxl .img-right, .xlarge .img-right, .rcsxl .imgRight, .xlarge .imgRight {
  margin-left: $spacingXl;
}

.ui-label {
  display: inline-block;
  margin: 0 1px $spacingXs 0;
  padding: 2px 6px;
  font-size: 11px;
  color: $white;
  background: $green;
  border: 1px solid $gray3;
  border-radius: 12px;
}

.ui-label-info {
  display: inline-block;
  margin: 0 1px $spacingXs 0;
  padding: 2px 6px;
  font-size: 11px;
  color: $white;
  //background: #007bc2;
  background: #42a2d6;
  border: 1px solid $gray3;
  border-radius: 12px;
}

/*.ui-label-premium {
  background: mix(#ffffff, $black, 24%);
  border: none;
  color: $white;
  font-family: MuseoSlab, Arial, sans-serif;
  padding: 3px 13px;
  border-radius: 14px;
  overflow: hidden;
  height: 17px;

  a {
    color: $white;
  }

  &:hover {
    background: mix(#ffffff, $black, 24%);
  }
}*/

.ui-badge {
  background-color: $green;
  border: 1px solid $gray3;
  color: $white;
  display: inline-block;
  font-weight: 600;
  padding: 1px 3px;
  @extend .bras;
}

.ui-badge.ui-badge-alt {
  //@include gradient-vertical(lighten($blueBright, 10%), $blueBright);
  //border-color: $blueBright;
}

.ui-tag {
  position: relative;
  display: inline-block;
  margin: 0 $spacingXs $spacingXs 0;
  padding: $spacingXs $spacingXl $spacingXs $spacingS;
  border-radius: $borderRadiusS;
  background: $gray1; 
  color: $white;
  white-space: nowrap;

  .remove {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: mix(#000000, $gray1, 10%);
    @include border-radius-right($borderRadiusS);
    padding: 0 ($spacingXs / 2);
    line-height: 2.1em;

    i {
      font-size: 12px;
      color: $white;
    }

    &:hover,
    &:focus {
      background: mix(#000000, $gray1, 25%);
    }
  }
}