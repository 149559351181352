// Post it notes
// =============

$postit-color-dark: #F7F9F9;
$postit-color-light: #F7F9F9;
$harvesterAvatarSize: 24px;

// A wrapper for two or more .postit
// ====================
.postitlist {
  .postit.is-highlighted {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .7);
  }

  .postit {
    &:first-of-type{
      margin-top: 0px;
    }
    overflow: visible;
    min-width: 200px; //Width from the shadow below.
    color: $gray2;
    font-size: 13px; //This should be rem or something - benoitg
    position: relative;
    display: block;
    background-color: $gray6;
    margin: 1em 0;
    &:hover {
      &[draggable=true]{cursor: move}
      a.segment-link {
        visibility: visible !important;
      }
    }

    // Quotation text
    // -----------
    .text-quotation {
      @extend %icon;
      margin: 0 0 1em 0;
      font-style: italic;
      font-size: $baseFontSize;
      @include word-wrap();

      &:before, &:after {
        content: '\e805';
        font-size: 9px;
        margin-right: 10px;
        color: $theme_text_icon_emphasis_color;
      }
      &:after {
        @include rotate(180);
        margin-left: 10px;
      }

    }

    a {
      font-style: normal;
      color: $theme_activelink_color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .postit-footer {
      width: 100%;
      position: relative;
      .extractOrigin {
        display: inline-block;
        .agentAvatar {
          float:none;
          display: inline-block;
        }
        .authorAvatar {
          display: inline-block;
        }
        a.segment-link {
          position: relative;
          //visibility: hidden;
          font-size: 12px;
        }
        .authorName {
          display: inline-block;
          vertical-align: top;
          font-size: 11px;
          a {
            color: $theme_person_name_color;
          }
        }
      }
      .segment-harvester {
        float: right;
        .creatorName {
          color: $gray4;
          font-size: 11px;
        }
      }
    }

  }
}
