.assembl-logo {
  text-decoration: none;
  background: url(#{$theme_header_logo}) center center no-repeat;
  background-size: contain;
  display: block;
  height: 100%;
  width: 115px;
}

.assembl-custom-logo img {
  max-width: 215px; /* MUST be the lower or equal to max-width property of .logo-container in _nav-bar.scss */
  max-height: 40px; /* MUST be the lower or equal to height property of .navbar .navbar-left ul .sub in _nav-bar.scss */
}

.white-mark {
  background: url(#{$theme_context_bg}) center center no-repeat;
  background-size: cover;
  height: $theme_context_page_header_height;
  display: table;
  width: 100%;
  padding: 0;
  .white-mark-content {
    display: table;
    width: 100%;
    height: 222px;
    .white-mark-title {
      display: table-cell;
      vertical-align: middle;
      > * {
        text-align: center;
        font-size: 50px;
        color: $theme_context_title_color;
        margin: 0;
      }
    }
  }
}

.debate-title {
  font-size: 14px;
  color: $theme_header_debate_title_color;
  .debate-icon {
    font-size: 145%;
    vertical-align: top;
    position: relative;
  }
  .icon-arrowright {
    color: $theme_box_border_color;
    font-size: 80%;
  }
}

.navbar {
  &.navbar-default {
    background-color: $theme_header_bg_color;
    color: $theme_header_text_color;
    height: $theme_header_height;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  }
}

// The F* and powerful wrapper
#wrapper {
  position: relative;
  height: 100vh;
  @include transform(translateX(0));
}

#header {
  height: $theme_header_height;
}

.authorization {
  width: 40%;
  margin: 6em auto 0 auto;
}

.modal-ajaxError {
  .popin-header {
    background-color: $gray1;
  }
  .content-ajaxError{
    .msg-error {
      color: $txtGray1;
    }
  }
  .error-close {
    color: $txtGray1;
  }
}

.modal-showSegment{
  .popin-header{
    background-color: $white;
  }

  .popin-body {
    @include border-radius(6px 6px 0 0);
  }

}

.modal-firstPost {
  text-align: center;
  .icon-discuss {
    color: $theme_text_icon_emphasis_color;
    font-size: 400%;
  }
}

.debateIntroductionContainer{
  display: table;
  height: 100%;
  width: 100%;
  .debateIntroductionContent{
    margin: 0 auto;
    padding: 5px 15px 15px 15px;
    background-color: rgb(250,250,250);
    display: table-cell;
    vertical-align: middle;
    .help1, .help2, .help3 {
      width: 50%;
      margin: 0 auto;
      color: $gray1;
    }
    .help3{
      font-size: 20px;
      margin-top: 55px;
    }
    .welcome, .help3{
      color: $theme_text_icon_emphasis_color;
    }
  }
}
