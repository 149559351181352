@charset "UTF-8";
@font-face {
  font-family: 'fontello';
  src: url('#{$fonts-dir}/fontello.eot?59577060');
  src: url('#{$fonts-dir}/fontello.eot?59577060#iefix') format('embedded-opentype'),
  url('#{$fonts-dir}/fontello.woff?59577060') format('woff'),
  url('#{$fonts-dir}/fontello.woff2?59577060') format('woff2'),
  url('#{$fonts-dir}/fontello.ttf?59577060') format('truetype'),
  url('#{$fonts-dir}/fontello.svg?59577060#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'fontello';
    src: url('#{$fonts-dir}/fontello.svg?66131057#fontello') format('svg');
  }
}

%icon:before, %icon:after,
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-checkbox:before { content: '\e800'; } /* '' */
.icon-collapse:before { content: '\e801'; } /* '' */
.icon-home:before { content: '\e802'; } /* '' */
.icon-book:before { content: '\e803'; } /* '' */
.icon-delete:before { content: '\e804'; } /* '' */
.icon-quote:before { content: '\e805'; } /* '' */
.icon-lock:before { content: '\e806'; } /* '' */
.icon-lock-open:before { content: '\e807'; } /* '' */
.icon-sheet:before { content: '\e808'; } /* '' */
.icon-comment:before { content: '\e809'; } /* '' */
.icon-timer:before { content: '\e80a'; } /* '' */
.icon-options:before { content: '\e80b'; } /* '' */
.icon-message:before { content: '\e80c'; } /* '' */
.icon-clipboard:before { content: '\e80d'; } /* '' */
.icon-synth:before { content: '\e80e'; } /* '' */
.icon-tableofideas:before { content: '\e80f'; } /* '' */
.icon-answer:before { content: '\e810'; } /* '' */
.icon-lab:before { content: '\e811'; } /* '' */
.icon-heart:before { content: '\e812'; } /* '' */
.icon-add:before { content: '\e813'; } /* '' */
.icon-arrowdown:before { content: '\e814'; } /* '' */
.icon-expand:before { content: '\e815'; } /* '' */
.icon-search:before { content: '\e816'; } /* '' */
.icon-bullet-point:before { content: '\e817'; } /* '' */
.icon-idea:before { content: '\e818'; } /* '' */
.icon-arrowright:before { content: '\e819'; } /* '' */
.icon-add-2:before { content: '\e81a'; } /* '' */
.icon-check:before { content: '\e81b'; } /* '' */
.icon-check-empty:before { content: '\e81c'; } /* '' */
.icon-arrowleft:before { content: '\e81d'; } /* '' */
.icon-infinite:before { content: '\e81e'; } /* '' */
.icon-discuss:before { content: '\e81f'; } /* '' */
.icon-user:before { content: '\e820'; } /* '' */
.icon-close:before { content: '\e821'; } /* '' */
.icon-diamond:before { content: '\e822'; } /* '' */
.icon-infinite-2:before { content: '\e823'; } /* '' */
.icon-download:before { content: '\e824'; } /* '' */
.icon-arrowup:before { content: '\e825'; } /* '' */
.icon-doc:before { content: '\e826'; } /* '' */
.icon-logout:before { content: '\e827'; } /* '' */
.icon-bell:before { content: '\e828'; } /* '' */
.icon-facebook:before { content: '\e829'; } /* '' */
.icon-twitter:before { content: '\e82a'; } /* '' */
.icon-gplus:before { content: '\e82b'; } /* '' */
.icon-google-oauth2:before { content: '\e82b'; } /* '' */
.icon-github:before { content: '\e82c'; } /* '' */
.icon-linkedin-squared:before { content: '\e82d'; } /* '' */
.icon-openid:before { content: '\e82e'; } /* '' */
.icon-reload:before { content: '\e82f'; } /* '' */
.icon-help:before { content: '\e830'; } /* '' */
.icon-ok:before { content: '\e831'; } /* '' */
.icon-mail:before { content: '\e832'; } /* '' */
.icon-imported:before { content: '\e833'; } /* '' */
.icon-circle-empty:before { content: '\e834'; } /* '' */
.icon-dot-circled:before { content: '\e835'; } /* '' */
.icon-graph:before { content: '\e836'; } /* '' */
.icon-info:before { content: '\e837'; } /* '' */
.icon-voting-day:before { content: '\e838'; } /* '' */
.icon-attach:before { content: '\e839'; } /*  */
.icon-yammer:before { content: '\e83a'; } /*  */
.icon-down:before { content: '\e83b'; } /* '' */
.icon-up:before { content: '\e83c'; } /* '' */
.icon-contra:before { content: '\e83d'; } /* '' */
.icon-handshake:before { content: '\e83e'; } /* '' */
.icon-goal:before { content: '\e83f'; } /* '' */
.icon-consequence:before { content: '\e840'; } /* '' */
.icon-balance-scale:before { content: '\e841'; } /* '' */
.icon-issue:before { content: '\e842'; } /* '' */
.icon-claim:before { content: '\e843'; } /* '' */
.icon-pro2:before { content: '\e844'; } /* '' */
.icon-contra2:before { content: '\e845'; } /* '' */
.icon-world:before { content: '\e846'; } /* '' */
.icon-puzzle:before { content: '\f12e'; } /* '' */
