$message-bar-width: 15px;
$messageFamilyImageWidth: 12px;
$avatarSize: 30px;
$readUnreadIndicatorSize: 7px;
$mesageSpacing: 6px;
$messagePaddingLR: 7px;
$messageBodyFontSize: 13px;
$mesageBodyLineHeight: 19px;
//To keep text readable
$messageBodyMaxWidth: 500px;
$messageBodyMinWidth: 300px; //If you bump it to the same value as messageBodyMaxWidth, the messagelist will no longer adapt to the available width
$ten: 10px;
$separatorBulletMargin: 0.6em;
$previewNumLines: 4;

// A message block
// ================




.message, .announcementMessage {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: $messagePaddingLR $messagePaddingLR+3*$mesageSpacing 7px $messagePaddingLR; //To balance the spacing of the message family on the left
  //margin: 0px $mesageSpacing 0px $mesageSpacing; //TODO: fix scroolbar margin
  min-height: $avatarSize;
  height: auto;
  color: $gray2;
  //border-top: solid 1px $gray3;
  @include transition(background-color 0.4s);

  &.announcementMessage {
    //.announceMessage Defined here instead of announcement.scss so constants can be reused and look kept in sync with message
    background-color: $white;
    border: none;
    box-shadow: 0px 0px 2px 2px $theme_announcement_box_border_color;
    padding: 15px $messagePaddingLR+3*$mesageSpacing 15px $messagePaddingLR+3*$mesageSpacing;
    .announcement_title {
      font-size: 18px;
      margin-bottom: $mesageSpacing;
    }
    .announcement_body {
      margin-bottom: 2*$mesageSpacing;
      font-size: $baseFontSize;
    }
    .creator_info {
      display: table;
      .avatarContainer {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        //width: $avatarSize;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .author_info_container {
        display: table-cell;
        vertical-align: middle;
        .message-date {
          font-size: 11px;
          color: $txtGray1;
        }
      }
    }
  }
  &.is-highlighted {
    background-color: $orange;
  }

  .inspirationSource a {
    color: $theme_activelink_color;
  }

  .avatarContainer {
    display: inline-block;
    vertical-align: middle;
    width: $avatarSize;
    //height: $avatarSize;
    margin: 0;
    padding: 0;
    img {
      padding: 0;
      margin: 0;
      width: $avatarSize;
      height: $avatarSize;
    }
  }
  // A message block Header
  // --------------------

  .head {
    position: relative; /* needed for the absolute positionning of readUnreadIndicatorClickZone */
  }

  .header_item_template {
    font-size: 12px; //This should be rem or something - benoitg
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message-headerAndDate {
    display: table;
  }
  .message-author {
    @extend .header_item_template;
    a {
      color: $theme_person_name_color;
    }
  }

  .message-date {
    @extend .header_item_template;
    color: $txtGray1;
    time {
    }
  }

  .message-subject {
    @extend .header_item_template;
    &.hidden {
      display: none !important;
    }
  }

  .js_messageTitle, .js_messageHeader {
    cursor: pointer;
  }

  .message-body.ellipsis {
    cursor: pointer;
    //Must match height: in message.js, so that there is an elipsis before
    //dotdotdot renders, and so that the height doesn't change.
    max-height: 52px;
  }

  .header-separator-bullet {
    margin-left: $separatorBulletMargin;
    &:before {
      content: "\002022"; //Unicode bullet
      color: $gray1;
      opacity: 0.5;
      padding-right: $separatorBulletMargin;

    }
  }

  a.readMoreOrLess {
    font-style: normal;
    color: $green;
    text-decoration: none;
    font-weight: bold;
    display: block;
    &.hidden {
      display: none;
    }
    &:hover {
      text-decoration: underline;
    }
    i {
      color: $green;
      font-size: 14px;
    }
  }

  .nb-nuggets {
    display: none;
    color: $gray4;
    i {
      font-size: 140%;
      color: $green;
    }
    span {
      vertical-align: top;
    }
  }

  &.viewStyleFullBody, &.viewStylePreview {
    /* Most styles are shared between the FullBody and Preview styles */
    line-height: 19.1px; /* 19px is not enough for message title: it cuts the bottom of the "g" letter */
    > .avatar {
      position: absolute;
    }
    > .readUnreadIndicator, > .readUnreadIndicator, > .message-author, > .message-subject, > .message-body, > .message-menu, > .readMoreOrLess, > .message-replybox {
      //So the first element of each line is aligned with the avatar
      //margin-left: $avatarSize + $mesageSpacing*2;
    }
    > * {
      vertical-align: middle;
    }
    .readUnreadIndicator {
      display: inline-block;
      /* Undo the avatar spacing when message indicator is there.  This hack is
         necessary because there will be no indicator of annonymous users,
         so the margin on message-author must clear the avatar on it's own */
      //margin-right: -$avatarSize - $mesageSpacing;
    }
    .message-author {
    }
    .message-subject {
      display: block;
      font-size: 15px;
      margin-top: 0.52em;
      max-width: $messageBodyMaxWidth - (2 * $messagePaddingLR);
    }
    .message-date {
      //@extend .header-separator-bullet;
      font-size: 11px;
    }
    .message-body {
      line-height: $mesageBodyLineHeight;
      min-width: $messageBodyMinWidth;
      margin-top: $mesageSpacing;
      font-size: $messageBodyFontSize;
    }
    .message-menu-actions {
      margin-top: $mesageSpacing;
      & * {
        opacity: 0.7;
      }
      & *:hover {
        opacity: 1;
      }

      .icon-download::before {
        font-size: 17px;
      }
    }
    .head {
      border-bottom: 2px solid $gray3;
    }
  }

  &.viewStyleFullBody {
    /* FullBody specific styles */

    .message-body a {
      word-break: break-all;
    }

    .message-body.body_format_text_plain {
      word-wrap: break-word;// avoid overlap content
      white-space: pre-line;
      padding-bottom: 15px;
      & > {
        white-space: normal;
      }
    }
    .message-body.body_format_text_html {
      white-space: normal;
      p {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      > div {

      }
    }

    .idea-classification {
      font-size: 12px;
      font-style: italic;
      color: $txtGray1;

      .idea-name:after {
        content: ", ";
      }

      .idea-name:last-child:after {
        content: "";
      }

      .idea-name-no-comma:after {
        content: "";
      }
    }

    &.Email .message-body.body_format_text_html {
      > div {
        white-space: pre-line;
      }
    }

    .message-body.body_format_text_plain,
    .message-body.body_format_text_html {
      .synthesisPanel {
        min-width: 0;
      }
    }

  }

  &.viewStylePreview {
    /* Preview specific styles */
    &:hover {
      .message-menu-actions {
        visibility: visible;
      }
    }
    .message-menu-actions {
      visibility: hidden;
    }

    .message-subject, .message-date {
      color: $txtGray2;
    }

    .message-body {
      position: relative;
      padding: 0px;
      //max-height: $previewNumLines * $mesageBodyLineHeight * $messageBodyFontSize;
      overflow: hidden;
      color: $txtGray2;
    }

    .message-body.body_format_text_plain > .elipsis > div {
      white-space: pre-line;
      & > {
        white-space: normal;
      }
      > div {
        height: inherit;
      }
    }

    .message-body.body_format_text_html {
      > div {
        height: inherit;
      }
    }

  }

  &.viewStyleTitleOnly {
    /* TitleOnly specific styles */
    &.message {
      width: 100%;
      max-width: 100%;
      max-height: $avatarSize;
      line-height: $avatarSize; //So text doesn't wrap even if avatar is bigish
      position: relative; /* needed for the absolute positionning of readUnreadIndicatorClickZone */
    }
    > * {
      vertical-align: middle;
    }
    .leftSection {
      float: left;
      white-space: nowrap;
      margin: 0px;
      text-align: left;
      .avatar {
        vertical-align: bottom;
      }
    }
    .rightSection {
      float: right;
      white-space: nowrap;
      margin: 0px;
    }
    .centerSection {
      display: block; //So sub elements do not wrap
      max-width: $messageBodyMaxWidth - 100px; //100px is arbitrary to leave space for avatars and name.  Normally what we whould constraint is the sum of (name + body) - avatar - date
      margin: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      .message-abstract {
        display: block; //So sub elements do not wrap
      }
    }
    .message-author {
      @extend .header_item_template;

      white-space: nowrap;
      margin: 0px 0px 0px $mesageSpacing; /*No left margin because there is an element on the left*/
    }

    .message-abstract {
      @extend .header_item_template;
      overflow: hidden;
      text-align: left;
      > div {
        height: $avatarSize;
      }
      > {
        white-space: normal;
      }
      .message-subject {
        @extend .header_item_template;
        @extend .header-separator-bullet;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $txtGray2;
      }
      .message-body {
        @extend .header_item_template;
        @extend .header-separator-bullet;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $gray1;
      }
    }

    .message-date {
      white-space: nowrap;
      margin: 0px $mesageSpacing 0 $mesageSpacing;
    }

    .readUnreadIndicatorClickZone {
      position: static;
    }
  }

  .messageAttachments {
    text-align:left;
    position: relative;    //So attachements scale
    .attachment {
      margin: $mesageSpacing 0px $mesageSpacing 0px;
    }
  }

  .readUnreadIndicatorClickZone {
    cursor: pointer;
    display: inline-block;
    padding: 0 $mesageSpacing 0 $mesageSpacing;
    margin-left: -$mesageSpacing;
    position: absolute;
    z-index: $zindex-read-unread-indicator;
    top: 0;
    right: 0;
  }

  .readUnreadIndicator {
    display: inline-block;
    box-sizing: border-box;
    width: $readUnreadIndicatorSize;
    height: $readUnreadIndicatorSize;
    vertical-align: middle;
    border: 1px solid $gray;
    @include border-radius(50px);
  }

  &.read {
    .readUnreadIndicatorClickZone:hover .readUnreadIndicator {
      background-color: $gray;
    }
  }


  &.unread {
    .message-author {
      font-weight: bold;
      a {
        color: $theme_person_name_color;
      }
    }
    .message-subject {
      font-weight: 600;
    }

    .readUnreadIndicatorClickZone:hover .readUnreadIndicator {
      background: none;
      border: 1px solid $gray;
    }

    .readUnreadIndicator {
      background-color: $theme_read_unread_item;
      border: 1px solid transparent;
    }

    .head {
      border-bottom: 2px solid $theme_read_unread_item;
    }
  }

  &.SynthesisPost {
    .message-subject {
      background-color: $theme_synthesis_header_bg;
      margin: 0;
      padding: $messagePaddingLR;
      color: $white;
    }
    .message-body {
      margin-top: 0;
    }
    .synthesisPanel-introduction,
    .synthesisPanel-conclusion,
    .message-subject {
      color: $white;
    }

    .synthesisPanel-ideas {
      .panel-editablearea {
        color: $black;
      }
      .synthesis-idea {
        min-height: 40px;
        /* commenting on an idea of a synthesis is temporary disabled
        &:hover {
          .comment {
            .synthesisIdea-replybox-openbtn {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        */
        .panel-editablearea {
          width: 100%;
        }
        .synthesis-expression {
          display: inline;
          margin: 0;
          & > *:first-child {
            margin-top: 0px;
          }
          & > p:last-child { /* TODO: Why was it "& > *:last-child"? Maybe it was for span.authors to be displayed right next to the last paragraph, but this breaks the margins of the ul elements. So I (Quentin) replace "*:last-child" by "p:last-child". */
            display: inline;
          }
        }
        .comment {
          .synthesisIdea-replybox-openbtn {
            text-decoration: none;
            font-size: 20px;
            visibility: hidden;
            opacity: 0;
          }
          .authors {
            font-size: 11px;
            font-style: italic;
            text-decoration: none;
            cursor: default;
          }
        }
      }

      .idealist-item {
        line-height: 38px;
      }

    }
  }

  &.SynthesisPost {
    &.viewStyleTitleOnly {
      .message-abstract {
        margin-left: 5px;
        padding: 0 $messagePaddingLR;
        max-height: 27px;
        overflow: hidden;
      }
    }
  }

  //
  .message-replybox {
    text-align: right;
    margin-top: 1em;
    &.hidden {
      display: none;
    }
    .attachments {
      text-align: left;
      .embeddedFile {
        margin-top: 1em;
        a {
          word-break: break-all;
        }
      }

      .attachmentEditable p {
        word-break: break-all; /* because text contains an URL */
      }
    }
  }

  //
  .message-menu {

    font-size: 1rem; //This should be rem or something - benoitg
    a {
      color: $theme_message_reply_btn_color;
      i {
        color: $theme_message_icon_color;
      }
      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }

    // used when buttons are to the left of the Reply button
    > .message-hoistbtn, > .message-linkbutton {
      vertical-align: top;
      width: 26px;
      line-height: 23px;
    }
    .message-replybox-openbtn {
      vertical-align: top;
      cursor: pointer;
      i {
        font-size: 10px;
      }
    }

    .dropdown {
      position: absolute;
      right: 20px;
      top: 0;
      vertical-align: top;
      padding: 0;
      list-style: none;
      .dropdown-menu {
        right: -20px; //Use the size always available on the right defined above
        left: auto;
        min-width: 400px;  //About the min size of the message panel - margin
        li > a {
          white-space: normal;  //Override default style so long text will wrap
        }
      }
      i.icon-mail {
        font-size: 12px; /* icon-mail needs to have a smaller font size (than original 16px) because the icon is big in the font */
      }
      a:link, a:visited {
        color: inherit; /* so that the icon is not purple when the "a" tag has an href attribute (link) */
        text-decoration: inherit;
        overflow: auto;
      }
      > a:link, > a:visited {
        color: $txtGray1;
      }
    }

  }
}

.message-deleted > .message-body {
  color: $txtGray1;
}

.message-translation {
  color: $txtGray2;

  .content {
    color: $txtGray1;
  }

  .content-question {
    color: $txtGray2;
  }

  .action:link, .action:visited {
    color: $theme_activelink_color;
  }

  .action:hover {
    color: $theme_activelink_color;
  }

  .action-seperated {
    margin-left: 5px;
    margin-right: 5px;
  }

  .dot-seperator {
    vertical-align: 1px; //Fudge number, for alignment
    font-size: 8px;
    color: $txtGray1;
    padding-left: 6px;
    padding-right: 6px;
  }

  .translation-question {
    border-left: 4px solid #efefef;
    padding-left: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}


// This is so annotator doesn't display a form for user's who aren't allowed
body.js_annotatorUserCannotAddExtract > .textbubble {
  display: none !important;
}

//
.message-replybox {
  .messageSend-body {
    height: 50px;
  }
  padding: {
    right: 0px;
    left: 0px;
  }
}
.printable .message {
  .message-menu {
    display: none !important;
  }
}

/* ------------------- Start of message family section ---------------------- */
// The arrow
// ------------
.message-family-arrow {
  display: block;
}
.message-family-arrow.noarrow {

  padding-left: $messageFamilyImageWidth/2;
  margin-left: $messageFamilyImageWidth/2;
}

.link-img {
  float: left;
  margin-left: -13px; //$messageFamilyImageWidth -1px
  margin-top: 0;
}

.message-family-arrow.arrow {
  padding-left: $messageFamilyImageWidth/2;
  margin-left: $messageFamilyImageWidth/2;

  .link-img {
    cursor: pointer;
  }
  .msg-img-closed {
    display: block;
  }
  .msg-img-open {
    display: none;
  }

  .message--expanded > .message-family-container > & {
    border-left: solid 1px #C7C6C2;
    margin-left: $messageFamilyImageWidth/2 - 1;
    .msg-img-closed {
      display: none;
    }
    .msg-img-open {
      display: block;
    }
  }
  .message--expanded > & {
    border-left: solid 1px #C7C6C2;
    margin-left: $messageFamilyImageWidth/2 - 1;
    .msg-img-closed {
      display: none;
    }
    .msg-img-open {
      display: block;
    }
  }
}

// An empty space for indentation
// --------------
.messagelist-space {
  display: block;
  padding-left: $messageFamilyImageWidth/2;
  margin-left: $messageFamilyImageWidth/2;
}

// The bar to make an .messagelist-item looks like a child
// --------------
.messagelist-bar {
  display: block;
  padding-left: $messageFamilyImageWidth/2;
  margin-left: $messageFamilyImageWidth/2 - 1;
  border-left: solid 1px #C7C6C2;
}

// The link between an messagelist and its parent
// --------------
.message-family-container {
  //box-sizing: border-box;
  // background: url(#{$img-dir}/messagelist/messagelist-link.png) -6 top no-repeat;
  display: block;
  position: relative; //So using 100% inside the message works...
  clear: left;
  padding-left: $messageFamilyImageWidth/2;
  margin-left: $messageFamilyImageWidth/2;
  background-color: $white;

  .message-conversation-block {
    box-sizing: border-box;
    display: table;
    @include calc(width, "100% + #{$messageFamilyImageWidth/2}");
    background-color: $gray6;
    padding: $mesageSpacing;
    padding-left: ($messagePaddingLR + $mesageSpacing + $mesageSpacing + $mesageSpacing);
    //Undo margins:
    margin-left: -$mesageSpacing;
    //margin-right:15
    i {
      vertical-align: middle;
      //color: $green;
      &.icon-comment {
        font-size: 18px;
        margin-right: -5px;
        margin-left: -5px;
      }
      &.icon-user {
        font-size: 12px;
        margin-right: -2px;
      }
    }

    .collapsed-thread-information {
      color: $txtGray1;
    }
  }
  &.bx {
    padding-top: 15px;
    padding-right: 0;
  }

  &.root {
    //Root does not have border, bump it right
    margin-left: 0;
    :before {
      border-right-color: #999;
      border-right-color: rgba(0, 0, 0, .25);
      border-left-width: 0;
    }
    .message-conversation-block {
      //Override width and margin calculation so block take the whole width
      margin-left: -$mesageSpacing;
      //width: 100% !important;  //!important to override calc(), which has a !important
      @include calc(width, "100% + #{$mesageSpacing}");
    }
  }

  &.child {
    // background: url(#{$img-dir}/messagelist/messagelist-link-last.png) -6 top no-repeat;
    border-left: solid 1px #C7C6C2;
    //Collapse border in the parent's border
    margin-left: $messageFamilyImageWidth/2 - 1;

    &.last-child {
      // background: url(#{$img-dir}/messagelist/messagelist-link-last.png) -6 top no-repeat;
      border-left: none;
      margin-left: $messageFamilyImageWidth/2
    }
  }
}

// ++++
.message-bar.message-bar--vertical {
  position: absolute;
  top: 4px;
  bottom: 0;
  height: auto;

  @for $i from 1 through 30 {

    &[data-bar-level="#{$i}"] {
      left: ((21px * $i) + 5px) - 21px;
    }

  }
}

// The child list
// --------------
.messagelist-children {
  //height: 0px;
  clear: left;
  display: block;
  position: relative;
  width: 100%;
  //@include transition(height 0.4s);
  //@include transform(translateZ(0));

  height: auto;

  &:empty {
    display: none;
  }

}

.printable {
  .link-img {
    display: none !important;
    }
  .message-family-arrow.arrow, .message-family-container.child {
    border-left: none !important;
  }
}
/* --------------------- End of message family section ---------------------- */


.messageModerationOptions {
  background-color: #ffdddd;
  margin: 5px 0;
  padding: 8px;
}
