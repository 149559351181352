// The idea panel specific CSS
// ===========================

.ideaPanel {
  .panel-body::-webkit-scrollbar {
    // overflow: visible;
    // display: none;
  }
}

.ideaPanel-section-widgets{
  i.icon-voting-day{
    font-size: 21px;
    margin: 0px 0px 0px -6px;
  }
}

.ideaPanel-section-summary {
  i.icon-info {
    font-size: 20px;
    margin: 0px 0px 0px -3px;
  }
}


.ideaPanel-longtitle-closebtn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.nugget-indice {
  position: relative;
  float: left;
  padding: 2px 0px;
  margin-top: 6px;

  .nugget {
    cursor: pointer;

    i:before {
      font-size: 150%;
      margin: 0;
      padding: 0;
      width: 24px;
    }

    .icon-diamond {
      color: lighten($gray, 20%);;
      margin: 0px 0px 0px 0px;
    }

    &:hover .icon-diamond {
      color: $orange1;
    }

    &.isSelected {
      .icon-diamond {
        color: $orange1;
      }
    }
  }
}

.add-description {
  cursor: pointer;
}

.ideaPanel-section-contributors {
    i.icon-user {
      margin: 0px 0px 0px -3px;
      font-size: 20px;
    }
    .contributor {
      font-size: 30px;
    }
}

.ideaPanel-section-segments .section-title {
  i.icon-diamond {
    font-size: 20px;
  }
}

.ideaPanel { 
  .postitlist {
    border: 1px dashed transparent;
  }
  &.is-dragover {
    .panel-body {
      border: 5px dashed #cccccc;
      
    }
    * {
        pointer-events: none;
      }

    .postitlist {
      border: 1px dashed #cccccc;
      @include border-radius(3px);
      //min-height: 20px; //So we see the border when the list is empty
    }
  }
}

.emptyIdeaPanelContainer {
  display: table;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .emptyIdeaPanelContent {
    display: table-cell;
    vertical-align: middle;
    color: #CCCCCC;
    .emptyIdeaIcon i{
      font-size: 100px;
    }
    .emptyIdeaIcon2 i{
      color: $theme_text_icon_emphasis_color;
      font-size: 20px;
    }
    .helperIdea {
      font-size: 16px;
    }
  }
}

.content-ideapanel{
  width: 100%;
  padding:16px;
  position: absolute;
  background-color: #FFF;
}
