.breadcrumb {
  display: inline; /* or "inline-block" if we want no line break in the same element (separated by ">") */
}

.breadcrumb:after {
  content: ">";
  font-weight: bold;
  color: $txtGray;
  padding-right: 5px;
  padding-left: 5px;
}

.breadcrumb:last-child:after {
    content: "";
}
