$section_separator_color: #EEEEEE;
$section_spacing: 18px;
$editablearea_hover_border_distance: 4px;
$editablearea_hover_border_size: 1px;

// The panel
// =========
.ideaList-panel{
  min-width:350px;
  .panelContents{
    min-width:350px;
  }
}
.messageList-panel{
  min-width:500px;
  .panelContents{
    min-width:500px;
  }
}
.ideaPanel-panel{
  min-width:295px;
  .panelContents{
    min-width:295px;
  }
}
.clipboard-panel{
  min-width:270px;
  .panelContents{
    min-width:270px;
  }
}
.synthesisPanel-panel{
  min-width:200px;
  .panelContents{
    min-width:200px;
  }
}
.navSidebar-panel{
  min-width:350px;
  .panelContents{
    min-width:350px;
  }
}
.contextPanel-panel{
  min-width:450px;
  .panelContents{
    min-width:450px;
  }
}
.minSizeGroup{
  .panel-header-title{
    display:none;
  }
  .panelContents{
    display:none;
  }
  .panelContentsWhenMinimized{
    display:block!important;
  }
}

.groupPanel {
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid $theme_box_border_color;
  overflow: hidden;
  .panelContents{
    width:100%;
  }
  .panel-section {
    position: relative;
    padding: $section_spacing 0px;
    border-bottom: solid 1px $section_separator_color;
    &>ul {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  /* We probably want to move this to a ckeditorfield.scss */
  .panel-editablebox.is-editing {
    .ckeditorField-seeMoreOrLess {
      .seeMore,
      .seeLess {
        cursor: pointer;
        text-decoration: none;
        float: right;
      }
    }
  }
  .panel-editablebox.is-editing {
    .panel-editablearea {
      color: $black;
      word-break: break-word;
      -ms-word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }

}

// When is dragging something on
// ****************
.panel.is-dragover {
}

/* Animations */
@include keyframes(spin) {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(360deg));
  }
}

@include keyframes(spinreverse) {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(-360deg));
  }
}

@include keyframes(grow) {
  0% {
    @include transform(scaleY(0));
    opacity: 0;
  }
  50% {
    @include transform(scaleY(1));
    opacity: 1;
  }
  100% {
    @include transform(scaleY(0));
    opacity: 0;
  }
}

@include keyframes(shatter) {
  0% {
    @include transform(scale(0));
  }

  50% {
    @include transform(scale(.50));
  }

  65% {
    @include transform(scale(.40));
    background-color: rgba(255, 255, 255, 1);
    border-width: 0px;
  }

  90% {
    background-color: rgba(255, 255, 255, 0);
    opacity: 1;
  }

  100% {
    @include transform(scale(1));
    border-width: 1px;
    background-color: transparent;
    opacity: 0;
  }
}

.animating {
  min-width: 0 !important;
}

// Vertical divisor
// ----------------
.panel-divisor {
}

// The header
// ----------
.panel-header {
  @include no-selection;
  * {
    @include no-selection;
  }
  @include z-index("float");
  height: #{$theme_header_height};
  padding: 0; /* required for minimized state */
  position: relative;
  background-color: $theme_header_panel;
  color: $white;
  //border-right: 1px solid $theme_header_separator;

  .panel-header-title,
  .panel-header-close {
    line-height: 40px;
    color: $theme_header_panel_text_color;
  }

  .panel-header-title {
    padding: 0 0 0 10px;
    i {
      font-size: 22px;
      color: $theme_header_panel_icon_color;
      margin-right: 2px;
    }
  }
}

.navSidebar .nav .panel-header-minimize,
.panel-header .panel-header-minimize {
  padding: 8px;
  .icon-arrowleft:before, .icon-arrowright:before {
    font-weight: bold !important;
  }
}

// An editable area within the .panel
// ----------------
.panel-editablearea {
  display: inline-block;
  width: 100%;
  min-height: 19px; //min-height must match line-height
  padding: $editablearea_hover_border_distance;
  margin: - $editablearea_hover_border_distance - $editablearea_hover_border_size; //Inverse of padding so content aligns like a normal element
  cursor: text;
  
  &.canEdit {
    border: $editablearea_hover_border_size solid transparent;
    &:hover {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px inset;
      border: $editablearea_hover_border_size solid #cccccc;
    }
  }

  &.panel-editablearea--small {
    width: auto;
    max-width: 240px;
    white-space: nowrap;
    margin: auto;
  }
}

// The container for an editablearea
// ------------------
.panel-editablebox {
  border: 1px solid transparent;
  position: relative;
  &.is-editing {
    background-color: initial;
    border: 1px solid #ddd;
  }
}

.panel-editablebox.is-editing > div:first-child,
.panel-editablebox.is-editing > div:last-child {
  background: #ddd;
}

/* CSS hack: targets Firefox >= 25 (source: browserhacks.com) */
@supports (-moz-appearance:meterbar) and (background-attachment:local) {
  .groupBody .groupPanel .panelContents {
    position: absolute;
  }
}

/* CSS hack: targets Chrome *, Safari *, Opera >= 14, Android * (source: browserhacks.com) */
.groupBody .groupPanel .panelContents:not(*:root) {
  position: absolute;
}

/* CSS hack: targets Internet Explorer >= 10 (source: browserhacks.com) */
_:-ms-input-placeholder, :root .groupBody .groupPanel .panelContents {
  position: absolute;
}

.groupBody .groupPanel .panelContents {
  position: relative; /* was "position: absolute;" for all browsers but there was a display bug with Firefox 24.8 */
  @include calc(height, "100vh - #{$theme_header_height} - #{$group_header_height}"); /* retain the height of everything on top of it. probably not the best solution because not dynamic */
}

.groupBody .groupPanel.minimizing .panelContents, .groupBody .groupPanel.unminimizing .panelContents {
  position: absolute; /* we temporary set position to absolute during animation, so the size animation does not get blocked */
}

.groupBody.editable .groupPanel .panelContents {
  @include calc(height, "100vh - #{$theme_header_height} - #{$group_editable_header_height}"); /* retain the height of everything on top of it. probably not the best solution because not dynamic */
}

.groupBody .groupPanel header ~ .panelContents {
  //@include calc(height, "100vh - #{$theme_header_height} - #{$group_header_height}"); /* need hack safari */
  @include calc(height, "100% - #{$theme_header_height} - #{$group_header_height}"); /* retain the height of the header */
}

/* CSS hack to target specificly IE >= 10 (see browserhacks.com). IE uses wrong 100% value in previous rule */
_:-ms-input-placeholder, :root .groupBody .groupPanel header ~ .panelContents {
  @include calc(height, "100vh - #{$theme_header_height} - #{$group_header_height} - 45px");
}

/* CSS hack to target specificly IE >= 10 (see browserhacks.com). IE uses wrong 100% value in previous rule */
/* Value of the first part of the calc() content must match the one defined in .popin-body > .groupsContainer rule in _groupContainer.scss */
_:-ms-input-placeholder, :root .popin-body .groupBody .groupPanel header ~ .panelContents {
  @include calc(height, "70vh - #{$theme_header_height} - #{$group_header_height}");
}

.groupBody.editable .groupPanel header ~ .panelContents {
  //@include calc(height, "100vh - #{$theme_header_height} - #{$group_header_height}"); need hack safari
  @include calc(height, "100% - #{$theme_header_height} - #{$group_header_height}"); /* retain the height of everything on top of it. probably not the best solution because not dynamic */
}

/* CSS hack to target specificly IE >= 10 (see browserhacks.com). IE uses wrong 100% value in previous rule */
_:-ms-input-placeholder, :root .groupBody.editable .groupPanel header ~ .panelContents {
  @include calc(height, "100vh - #{$theme_header_height} - #{$group_editable_header_height} - 45px");
}

/* CSS hack to target specificly IE >= 10 (see browserhacks.com). IE uses wrong 100% value in previous rule */
/* Value of the first part of the calc() content must match the one defined in .popin-body > .groupsContainer rule in _groupContainer.scss */
_:-ms-input-placeholder, :root .popin-body .groupBody.editable .groupPanel header ~ .panelContents {
  @include calc(height, "70vh - #{$theme_header_height} - #{$group_editable_header_height}");
}

.panelContents > div {
  /* probably not the best solution */
  height: 100%;
  //height: 100vh; need hack safari
}

#groupContainer > .groupsContainer {
  width: 100%;
}

// To put the content
// -----------
.panel-body {
  //The scrollbars do not work with position relative
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow: auto;
  &:hover {
    //overflow: auto;
  }
  .groupPanel.is-dragover & {
    background-color: $yellow;
  }
  .panel-content-title {
    font-size: 19px;
    padding: ($section_spacing)-16px 0px $section_spacing 0px; //-16px is to nullify panel content padding, currently defined on ideaPanel
    border-bottom: solid 1px $section_separator_color;
  }
  .section-title {
    color: $txtGray2;
    font-size: 16px;
    font-weight: 300;
    margin: 0px 8px 18px 0px;
    i.icon{
      color: $theme_text_icon_emphasis_color;
      }
    .title-text {
      margin-left: 6px;
    }
  }
  .sub-section-title {
    color: $txtGray1;
    font-size: 11px;
    text-transform: uppercase;
    margin: 10px 0px 4px 0px;

    //margin: 6px 8px 6px 0px;
    //font-weight: 300;
  }
}

.panel-body--has-footer {
  bottom: 2px !important;
}

// The footer
// ----------
.panel-footer {
  @extend .panel-header;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e4e4e3;
  padding-right: 10px;
  background-color: $white;
}
