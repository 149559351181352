// Box used when user drag something
// ======================
.dragbox {
  line-height: 20px;
  //padding: 5px;

  position: absolute;
  top: 10px;
  left: 10px;
  @include z-index("underground");

  background-color: $yellow;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .15);
  border: solid 1px $orange;

  font-size: 11px;
  color: #c7a25a;
  cursor: move;

  &:before {
    content: url(#{$img-dir}/dragbox/dragbox-background.png);
    margin-right: 5px;
  }
}
