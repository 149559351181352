.adminContent {
  max-width: 80%;
  margin: 4em auto 0 auto;
}

/* user profile */
table {
  &.admin {
    border: none;

    thead {
      th {
        border-bottom: none;
      }
    }
  }
}

.admin-panel {
  .admin-section{
    background-color:$white;
    max-width:600px;
    min-width:560px;
  }
  .content-admin-discussion{
    padding-top: 20px;
  }
}

.admin-section {
  .is-loading{
    cursor: wait;
    &:after {
      @include z-index("float");
      display: block;
      content: "";
      background: rgba(255, 255, 255, 0.10) url(#{$img-dir}/panel/loading.gif) no-repeat center center;
      width: 100%;
      height: 20px;
    }
  }
}


.admin-panel {
  .sidebar-nav {
    ul > li {
      list-style: none;
    }
  }

  .adminMenu {
    .menu {
      display: block;
      padding: 6px 0;
      font-size: 14px;
      color: #82888a;
    }
    .active {
      .menu {
        text-decoration: none;
        color: #565a5c;
        font-weight: bold;
      }
    }
  }

  .admin-title {
    color: #565a5c;
    font-size: 16px;
    border-bottom: 1px solid #dce0e0;
    padding: $spacingS $spacingS 0 $spacingS;
  }

  .content-container {
    box-sizing: border-box;
  }

  .admin-section {
    .section {
      background-color: $white;
    }
  }

  #discussion-settings,
  #profile-form,
  #adminDiscussion,
  #account-form{
    .form-actions{
      padding: 15px 20px 15px 0px;
      margin: 0;
      border-top: 1px solid #dce0e0;
      box-sizing: border-box;
      width: 100%;
    }

    input:invalid {
      border: 3px inset red;
    }

  }

  #adminDiscussion {
    .control-label {
      padding: 5px 10px 10px 20px;
    }
  }

  #profile-form {
    .control-label {
      width: auto;
    }
    .social-photo {
      width: 595px;
      .custom-radio {
        height: 30px;
        input[type=radio] {
          margin-top: 8px;
        }
        .sc, .sc-name {
          line-height: 30px;
        }
      }
    }

    .user-lang {
      margin-left: 115px;
    }
    .controls {
      margin-left: 20px;

      .social-content {
        width: 540px;
        .facebook-bg, .twitter-bg ,.google-oauth2-bg {
          line-height: 37px;
        }
      }
    }
    .email-helper,
    .social-helper {
      width: 540px;
      color: $gray1;
    }
    .ac_add_email {
      .icon-add {
        margin-left: -7px;
      }
    }
  }

  #adminDiscussion {
    .controls {
      margin-left: 20px;
    }
    #introduction {
      resize: vertical;
    }
  }

}
.admin-account, .admin-profile, .admin-notifications, .admin-notifications{
  width:100%;
  .admin-section{
    .section{
      margin:0 0 30px 0;
    }
    .control-group{
      margin: 0 auto;
      width:94%;
      padding:0 10px 20px 10px;
    }
    .h3{
      margin:0;
      padding:5px 0 15px 20px!important;
    }
    .content-container{
      padding:30px 0 8px 0;
    }
    .form-actions{
      padding:25px 36px 15px 0px !important;
    }
  }
}
.help-admin{
      text-align: right;
    font-style: italic;
    color:#7F8C8D;
}
.btn-s{
  font-size:12px;
  font-weight:400;
}
#associate-account{
  .associate-email {
    .icon-checkbox{
      font-size: 20px;
      vertical-align: middle;
    }
  }
}

#partner-content{
  .description {
    word-wrap: break-word;
  }
  .partner-img-content {
    display: table;
    width: em(200);
    height: em(200);
    margin: 0 auto;
    .partner-img {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      img{
        max-width: em(200);
        max-height: em(200);
      }
    }
  }
}

#create-source{
  p{
    padding: 0 0 20px 0;
  }
  button{
    margin: 0 0 20px 0;
  }
}