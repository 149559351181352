.modal-ideas-showing-message .bbm-modal.bbm-modal--open {
  max-width: 800px;

  .popin-body {
    max-height: calc(80vh - 45px - 15px);
    overflow: scroll;
  }
}

.ideas-showing-message {
  line-height: 1.7;
  font-size: 13px; 

  .ideas-showing-message-introduction {
    font-weight: 600;
    font-size: 14px;
  }
  .ideas-reasons-collection {
    .items > div {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .items > div:last-child {
      border: none;
    }

    .left-column {
      display: inline-block;
      width: calc(100% - 250px);
    }

    .right-column {
      float: right;
      margin-top: 10px;
      max-width: 245px;
    }

    .author, .harvester {
      display: inline-block;
    }

    .idea, .extract {
      border-left: 4px solid #efefef;
      padding-left: 6px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .breadcrumb {
      color: $gray4;
      font-weight: 300;
      font-style: italic;
    }

    .breadcrumb:last-child {
      color: $theme_activelink_color;
      font-weight: 600;
    }

    .idea-reason-content {
      margin-top: 5px;
    }

    .extract {
      color: $gray4;
      font-style: italic;
      font-weight: 300;
    }

    .thread-title {
      display: inline-block;
      font-style: italic;
    }

    .icon, .thread-title {
      font-style: italic;

      > .extract-text {
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
}
