html, body, h1, h2, h3, h4, h5, h6, p, div, span, applet, object, iframe,
blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  //font: inherit;
  //font-size: 100%;
  vertical-align: baseline;
}

// Display in IE6-9 and FF3
// Source: http://github.com/necolas/normalize.css
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Display in IE6-9 and FF3
// Source: http://github.com/necolas/normalize.css
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary,
menu {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

a img {
  border: none;
}

img {
  vertical-align: middle;
}

.preload * {
  @include transition(none !important);
}

[contenteditable] {
  line-height: 22px;
  padding: 5px;
  outline: 0;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

[hidden] {
  display: none;
}

output {
  * {
    margin: {
      top: 0;
      right: 5px;
      bottom: 0;
      left: 0 !important;
    }
    padding: 0;
    display: inline;
  }
}

textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}