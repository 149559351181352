/* Modal positioning */
.popin-wrapper {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100; /* has to be inferior to the z-index of #popover-oembed (defined in assembl/static/css/base/_common.scss) */
  padding: 0px 10px;
  overflow-x: auto;
  overflow-y: auto;
}

.popin-without-footer .popin-footer {
  display: none;
}

.popin-wrapper * {
  box-sizing: border-box;
}

.popin-wrapper {
  background: rgba(0, 0, 0, 0.75);
  -webkit-transition: background-color 0.3s;
}

.popin, .bbm-modal {
  margin: auto;
  width: auto;
  max-width: 90%;
  margin: 15px auto 0;
  box-shadow: 0 0 12px rgba($black, (25/100));
  -webkit-animation: popin-fadein 0.15s;
  animation: popin-fadein 0.15s;
  @include border-radius($borderRadiusM);

  .background-grey {
    background: #ececec !important;
  }

  .background-lightgrey {
    background: #f4f4f4 !important;
  }

  .popin-header {
    position: relative;
    padding: 15px;
    min-height: 45px;
    background: $theme_popin_header_bg;
    color: $theme_popin_header_text_color;
    border-radius: $borderRadiusM $borderRadiusM 0 0;
    @include clearfixHack();

    h1, h2, h3, h4, h5, h6 {
      color: $white;
      margin: 0;
      padding: 0;
    }

    &.empty {
      background: $white;
    }

    .close {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 0 12px;
      line-height: 3.5em;
      color: $white;
      font-size: 14px;
      text-decoration: none;
      border-top-right-radius: $borderRadiusM;
      opacity: initial; /* necessary to override .close of growl component... */

      &:hover, &:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        filter: alpha(opacity=50);
        opacity: .5;
      }

      span {
        display: inline-block;
        width: 0;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
        @include transition(max-width 0.3s);
      }

      i {
        margin-right: -0.2em;
        color: $white;
        vertical-align: top;
      }
    }
  }

  .popin-body {
    padding: 15px;
    background: $white;

    &:last-child {
      border-radius: 0 0 $borderRadiusM $borderRadiusM;
    }
  }

  .popin-footer {
    @include clearfixHack();
    padding: 15px;
    background: $white;
    border-radius: 0 0 $borderRadiusM $borderRadiusM;
  }
}

// END of new modal

/* ANIMATIONS */
/* Open modal */

@include keyframes(popin-open) {
  0% {
    -webkit-transform: matrix(0.99126, 0, 0, 0.99126, 0, 43.8813);
    opacity: 0.1259;
  }

  4% {
    -webkit-transform: matrix(0.99295, 0, 0, 0.99295, 0, 45.06809);
    opacity: 0.29544;
  }

  8% {
    -webkit-transform: matrix(0.99467, 0, 0, 0.99467, 0, 46.26922);
    opacity: 0.46703;
  }

  12% {
    -webkit-transform: matrix(0.99619, 0, 0, 0.99619, 0, 47.33355);
    opacity: 0.61908;
  }

  16% {
    -webkit-transform: matrix(0.99743, 0, 0, 0.99743, 0, 48.19991);
    opacity: 0.74284;
  }

  20% {
    -webkit-transform: matrix(0.99837, 0, 0, 0.99837, 0, 48.86067);
    opacity: 0.83724;
  }

  24% {
    -webkit-transform: matrix(0.99905, 0, 0, 0.99905, 0, 49.33658);
    opacity: 0.90523;
  }

  28% {
    -webkit-transform: matrix(0.99952, 0, 0, 0.99952, 0, 49.66049);
    opacity: 0.9515;
  }

  32% {
    -webkit-transform: matrix(0.99981, 0, 0, 0.99981, 0, 49.8675);
    opacity: 0.98107;
  }

  36% {
    -webkit-transform: matrix(0.99999, 0, 0, 0.99999, 0, 49.98966);
    opacity: 0.99852;
  }

  40% {
    -webkit-transform: matrix(1.00008, 0, 0, 1.00008, 0, 50.05361);
    opacity: 1.00766;
  }

  44% {
    -webkit-transform: matrix(1.00011, 0, 0, 1.00011, 0, 50.08);
    opacity: 1.01143;
  }

  48% {
    -webkit-transform: matrix(1.00012, 0, 0, 1.00012, 0, 50.08394);
    opacity: 1.01199;
  }

  52% {
    -webkit-transform: matrix(1.00011, 0, 0, 1.00011, 0, 50.07589);
    opacity: 1.01084;
  }

  56% {
    -webkit-transform: matrix(1.00009, 0, 0, 1.00009, 0, 50.06265);
    opacity: 1.00895;
  }

  60% {
    -webkit-transform: matrix(1.00007, 0, 0, 1.00007, 0, 50.04833);
    opacity: 1.0069;
  }

  64% {
    -webkit-transform: matrix(1.00005, 0, 0, 1.00005, 0, 50.03518);
    opacity: 1.00503;
  }

  68% {
    -webkit-transform: matrix(1.00004, 0, 0, 1.00004, 0, 50.02421);
    opacity: 1.00346;
  }

  72% {
    -webkit-transform: matrix(1.00002, 0, 0, 1.00002, 0, 50.01567);
    opacity: 1.00224;
  }

  76% {
    -webkit-transform: matrix(1.00001, 0, 0, 1.00001, 0, 50.00941);
    opacity: 1.00134;
  }

  80% {
    -webkit-transform: matrix(1.00001, 0, 0, 1.00001, 0, 50.00506);
    opacity: 1.00072;
  }

  84% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 50.00223);
    opacity: 1.00032;
  }

  88% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 50.0005);
    opacity: 1.00007;
  }

  92% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 49.99956);
    opacity: 0.99994;
  }

  96% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 49.99913);
    opacity: 0.99988;
  }

  100% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 1;
  }
}

.popin-open, .bbm-modal--open {
  -webkit-animation-duration: 0.3s;
  -webkit-animation-name: popin-open;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  -webkit-transform-origin: 50% 50%;
  -webkit-backface-visibility: hidden;
}

/* Close a popin */
@include keyframes(popin-close) {
  0% {
    -webkit-transform: matrix(0.99874, 0, 0, 0.99874, 0, 49.1187);
    opacity: 0.8741;
  }

  4% {
    -webkit-transform: matrix(0.99705, 0, 0, 0.99705, 0, 47.93192);
    opacity: 0.70456;
  }

  8% {
    -webkit-transform: matrix(0.99533, 0, 0, 0.99533, 0, 46.73078);
    opacity: 0.53297;
  }

  12% {
    -webkit-transform: matrix(0.99381, 0, 0, 0.99381, 0, 45.66645);
    opacity: 0.38092;
  }

  16% {
    -webkit-transform: matrix(0.99257, 0, 0, 0.99257, 0, 44.80009);
    opacity: 0.25716;
  }

  20% {
    -webkit-transform: matrix(0.99163, 0, 0, 0.99163, 0, 44.13933);
    opacity: 0.16276;
  }

  24% {
    -webkit-transform: matrix(0.99095, 0, 0, 0.99095, 0, 43.66342);
    opacity: 0.09477;
  }

  28% {
    -webkit-transform: matrix(0.99049, 0, 0, 0.99049, 0, 43.33951);
    opacity: 0.0485;
  }

  32% {
    -webkit-transform: matrix(0.99019, 0, 0, 0.99019, 0, 43.1325);
    opacity: 0.01893;
  }

  36% {
    -webkit-transform: matrix(0.99002, 0, 0, 0.99002, 0, 43.01034);
    opacity: 0.00148;
  }

  40% {
    -webkit-transform: matrix(0.98992, 0, 0, 0.98992, 0, 42.94639);
    opacity: -0.00766;
  }

  44% {
    -webkit-transform: matrix(0.98989, 0, 0, 0.98989, 0, 42.92001);
    opacity: -0.01143;
  }

  48% {
    -webkit-transform: matrix(0.98988, 0, 0, 0.98988, 0, 42.91606);
    opacity: -0.01199;
  }

  52% {
    -webkit-transform: matrix(0.98989, 0, 0, 0.98989, 0, 42.92411);
    opacity: -0.01084;
  }

  56% {
    -webkit-transform: matrix(0.98991, 0, 0, 0.98991, 0, 42.93736);
    opacity: -0.00895;
  }

  60% {
    -webkit-transform: matrix(0.98993, 0, 0, 0.98993, 0, 42.95167);
    opacity: -0.0069;
  }

  64% {
    -webkit-transform: matrix(0.98995, 0, 0, 0.98995, 0, 42.96482);
    opacity: -0.00503;
  }

  68% {
    -webkit-transform: matrix(0.98997, 0, 0, 0.98997, 0, 42.97579);
    opacity: -0.00346;
  }

  72% {
    -webkit-transform: matrix(0.98998, 0, 0, 0.98998, 0, 42.98433);
    opacity: -0.00224;
  }

  76% {
    -webkit-transform: matrix(0.98999, 0, 0, 0.98999, 0, 42.99059);
    opacity: -0.00134;
  }

  80% {
    -webkit-transform: matrix(0.98999, 0, 0, 0.98999, 0, 42.99494);
    opacity: -0.00072;
  }

  84% {
    -webkit-transform: matrix(0.99, 0, 0, 0.99, 0, 42.99777);
    opacity: -0.00032;
  }

  88% {
    -webkit-transform: matrix(0.99, 0, 0, 0.99, 0, 42.9995);
    opacity: -7e -5;
  }

  92% {
    -webkit-transform: matrix(0.99, 0, 0, 0.99, 0, 43.00044);
    opacity: 6.0 -05e;
  }

  96% {
    -webkit-transform: matrix(0.99, 0, 0, 0.99, 0, 43.00088);
    opacity: 0.00012;
  }

  100% {
    -webkit-transform: matrix(0.99, 0, 0, 0.99, 0, 43);
    opacity: 0;
  }
}

.popin-close {
  -webkit-animation-duration: 0.43333s;
  -webkit-animation-name: popin-close;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  -webkit-transform-origin: 50% 50%;
  -webkit-backface-visibility: hidden;
}

.modal-ckeditorfield{
  .bbm-modal{
    max-width: 800px;
    width: 60%;
    .panel-editablebox{
      margin: 6px 0 0 10px;
      p{
        font-size:13px;
        line-height:21px;
      }
      p:not(:last-child){
        margin: 0 0 10px 0;
      }
    }
  }
}
