// Adapted from Flipswitch component of Tetra UI ( https://github.com/viadeo/tetra-ui , Licence: MIT)

$flipswitchWidth: 48px; // was 56
$flipswitchHeight: 20px; // was 24
$filpswitchButtonMargin: 2px;

$filpswitchButtonWidth: ($flipswitchHeight - (2 * $filpswitchButtonMargin)); // = 18
$filpswitchButtonBorderRadius: 12px;

.flipswitch {
	label {
		display: inline;
		text-transform: uppercase;
		cursor: pointer;

		input {
			display: none;
		}
	}

	.flipswitch-lbl-yes {
		color: $black;
	}

	.flipswitch-lbl-no {
		color: lighten($black, 80%);
		&:hover {
			color: $black;
		}
	}

	.flipswitch-sliders {
		display: inline-block;
		position: relative;
		width: $flipswitchWidth;
		height: $flipswitchHeight;
		margin: 0 $spacingXs;
		border-radius: $filpswitchButtonBorderRadius;
		vertical-align: middle;
		//cursor: default;
		cursor: pointer;
	}

	.flipswitch-outer {
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: $filpswitchButtonBorderRadius;
	}

	.flipswitch-inner {
		display: block;
		width: 200%;
		height: 100%;
		margin-left: -100%;

		@include transition(margin-left 0.2s ease-in);
	}

	.flipswitch-btn-no, .flipswitch-btn-yes {
		float: left;
		width: 50%;
		height: 100%;
		box-shadow: 0 1px 3px rgba($black, (30/100)) inset;
	}

	.flipswitch-btn-no {
		background: lighten($black, 70%);
		.flipswitch-btn-no-background-text {
			float: right;
			line-height: $flipswitchHeight - 2px;
			text-align: right;
			margin-right: $filpswitchButtonWidth + 7px;
			color: $white;
			//font-weight: bold;
		}
	}

	.flipswitch-btn-yes {
		float: right;
		//background: $green1;
		background: $theme_btn_primary_bg_color;
		.flipswitch-btn-yes-background-text {
			line-height: $flipswitchHeight - 2px;
			padding-left: $filpswitchButtonWidth + 7px;
			color: $theme_btn_primary_text_color;
			//font-weight: bold;
		}
	}

	.flipswitch-slider {
		position: absolute;
		left: 0;
		top: $filpswitchButtonMargin;
		width: $filpswitchButtonWidth;
		height: $filpswitchButtonWidth;
		margin-left: 0;
		margin-right: 0;
		border-radius: $filpswitchButtonBorderRadius;
		background: $white;
		text-align: center;
		line-height: $filpswitchButtonWidth;
		font-size: 8px;
		color: mix(#000000, $white, 15%);
		cursor: pointer;

		@include transition(left 0.2s ease-in, margin-left 0.2s ease-in 0.1s, margin-right 0.2s ease-in 0.1s, background 0.2s ease-in);

	}

	&:hover .flipswitch-slider {
    	background: mix(#000000, $white, 5%);
    }

	&.flipswitch-no {
		.flipswitch-lbl-yes {
			color: lighten($black, 70%);
			&:hover {
				color: $black;
			}
		}
		.flipswitch-lbl-no {
			color: $black;
		}
		.flipswitch-inner {
			margin-left: 0;
		}
		.flipswitch-slider {
			@include calc(left, "100% - #{$filpswitchButtonWidth} - #{$filpswitchButtonMargin}");
		}
	}

	&.flipswitch-yes {
		.flipswitch-slider {
			left: $filpswitchButtonMargin;
		}
	}
}
