#main {
  //height: 100%;
}
#groupContainer {
  height: 100%;
}
#groupContainer > .groupsContainer {
  position: absolute;
  top: $theme_header_height;
  right: 0;
  bottom: 0;
  left: 0;
  @include calc(height, "100% - #{$theme_header_height}");
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  > * {
    white-space: normal;
  }
}

@for $i from 1 through 15 {
#groupContainer.hasInfobar-#{$i} > .groupsContainer {
  position: absolute;
  top: #{$theme_header_height + $i * $infobar_height};
  @include calc(height, "100% - #{$theme_header_height} - #{$i * $infobar_height}");
}
}

.popin-body > .groupsContainer {
  height: 70vh; /* value must match first part of calc() in _panel.scss for rules like "something .popin-body something .panelContents" */
  .groupContent {
    /* this is a fix for the size of the popin content in IE (content was cut at the bottom even with a scrollbar). This solution for the fix has the advantage of not creating 2 scrollbars */
    overflow-y: auto;
  }
}

.groupContent {
  display: inline-block;
  height: 100%;
  white-space: normal;
  box-sizing: border-box;
  .navNotification {
    position: relative;
    background-color: $gray3;
    color: $white;
  }
}

.groupHeader {
  &.editable {
    height: $group_editable_header_height;
  }
  height: $group_header_height;
  background-color: $theme_panel_group_header_bg_color;
  position: relative;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  .close-group {
    cursor: pointer;
    position: absolute;
    text-decoration: none;
    right: 12px;
    font-size: 14px;
    opacity: 0.8;
    top: 3px;
    color: $theme_panel_group_header_close_color;
    &:hover {
      opacity: 1;
    }
  }
}
.groupPanel{
  display:table-cell;
}
.groupBody {
  display: table;
  height: calc(100% - #{$group_header_height}); /* retain the height of .groupHeader */
  width: 100%;
  &.editable {
    height: calc(100% - #{$group_editable_header_height}); /* retain the height of .groupHeader */
  }
  table-layout: auto;

  .groupPanel {
    height: 100%;
    vertical-align: top;
    .panelContentsWhenMinimized {
      display: none;
      span {
        /* the icon */
        font-size: 25px;
        position: absolute;
        text-align: center;
        padding-top: 2px;
      }
    }
    .lock-group {
      margin-left: auto;
      margin-right: auto;
      right: 30px;
      opacity: 0.8;
      top: 5px;
      padding-top: 4px;

      .lockedGlow {
        animation-name: glowlock;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        -webkit-animation-name: glowlock;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  .navSidebar {
    height: 100%;
    padding: 0px;
    .side-menu {
      margin: 0;
      padding: 0;
      list-style: none;
      //height: 100vh; need hack safari
      height: 100%;
      display: block;
      background-color: $theme_navigation_menu_bg;
      > li {
        position: relative;
        > a.nav {
          display: block;
          height: 40px;
          text-decoration: none;
          background-color: $theme_navigation_menu_bg;
          color: $theme_navigation_text_color;
          padding-left: 10px;
          font-size: $smallFontSize;
          &.active {
            background-color: $theme_navigation_menu_bg_active;
            color: $theme_navigation_text_color_active;
            i:not(.icon-arrowleft) {
              color: $theme_navigation_icon_color_active;
            }
          }
          &:hover {
            background-color: $theme_navigation_menu_bg_hover;
          }
          i {
            font-size: 22px;
            color: $theme_navigation_icon_color;
            vertical-align: sub;
          }
          i.icon-add-2 {
            color: $white;
          }
          .icon-arrowleft {
            font-size: 14px;
          }
        }
        > a:first-child {
          //border-right: 1px solid $theme_header_separator;
        }
        .second-level {
          display: none;
          box-sizing: border-box;
        }
        .second-level.about {
          overflow: auto;
        }

        .second-level.synthesis,
        .second-level.debate,
        .second-level.visualizations,
        .second-level.about {
          background-color: $theme_navigation_bg;
        }
      }
      .debate {
        .panel-body {
          top: 0;
          bottom: 0;
        }
      }
      .synthesisNavPanel {
        .synthesisListHeader {
          padding: 15px;
          position: absolute;
        }
        ul.synthesisList {
          list-style-type: none;
          padding: 0;

          li:hover {
            cursor: pointer;
            background-color: $theme_navigation_or_idea_bg_active;
            //border-right: 4px solid $green;
          }
          li {
            margin: 0;
            padding: 10px;
            clear: both;
            min-height: 20px;
            display: block;
            border-bottom: 1px solid #ddd;
            border-top: none;
            position: relative;
            border-right: 4px solid transparent;

            .title {
              vertical-align: middle;
              width: 80%;
              display: inline-block;
              color: $theme_navigation_idea_text_color;
            }
            .date {
              vertical-align: middle;
              color: $theme_navigation_idea_total_count;
              display: inline-block;
              width: 20%;
              text-align: center;
            }
          }
          li.selected {
            background-color: $theme_item_selected_color;
            border-right: 4px solid $theme_item_selected_secondary_color;
          }
        }
      }
    }
  }

  .synthesisPanel {
    position: relative;
  }

  .clipboard {
    position: relative;
  }

}

.groupPanel .panel-header a.panel-header-minimize,
.groupPanel .navSidebar .nav .panel-header-minimize {
  color: $theme_btn_primary_text_color;
  float: right;
  i.icon-arrowleft,
  i.icon-arrowright {
    font-size: 14px;
  }
}

.groupPanel.minimized {
  .panel-header {
    a {
      display: none;
    }
  }
}

.groupPanel.minimizing .panel-header a {
  display: none;
}

.groupPanel.minimizing .panel-header a.panel-header-minimize {
  display: block;
}

.groupPanel.minimized .panel-header a.panel-header-minimize,
.groupPanel.minimized .navSidebar .nav span.panel-header-minimize,
.groupPanel.minimized .panelContentsWhenMinimized.hasNoHeader a.panel-header-minimize {
  display: block;
  color: $theme_btn_primary_text_color;
}

.generic-modal {
  .popin, .bbm-modal {
    max-width: 500px;
  }
}

.panelGroups-modal {
  .groupBody.editable {
    height: calc(100% - #{$group_header_height}); /* retain the height of .groupHeader */
  }

  .popin, .bbm-modal {
    min-height: 90%;
    .popin-body {
      padding: 0px;
      .groupHeader {
        display: none;
      }
    }
  }
}

.partner-modal {
  .popin, .bbm-modal {
    max-width: 550px;
  }
}

.iframe-popin {
  .popin, .bbm-modal {
    min-width: initial;
    max-width: 1400px;
    display: table;
    width: initial;

    .popin-header {
      display: block;
    }

    .popin-body {
      padding: 0;
      display: inline-block;
      width: 100%;

      iframe {
        width: 100%;
        height: 50px;
      }
    }
    .popin-footer {
      padding: 3px;
    }
  }
}

.popin-fixed-size {
  .popin, .bbm-modal {
    min-width: 1020px !important;
    width: 100%;
    .popin-body {
      iframe {
        height: calc(100vh - 150px);
      }
    }
  }
}

/* This is used in the "Add a panel group" popin */
.selectedGroup {
  max-width: 500px;
  .itemGroup {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 5px auto;
    text-align: center;
    border: 1px solid transparent;
    color: $gray2;
    display: block;
    text-decoration: none;
    i {
      font-size: 3.5em;
    }
    .icon-checkbox {
      position: absolute;
      font-size: 16px;
      top: 1px;
      left: 1px;
      visibility: hidden;
    }
    i:nth-of-type(2) {
      position: absolute;
      left: 0;
      top: 2px;
      width: 100%;
      height: 100%;
    }
    &:hover {
      @include border-radius(3px);
      border: 1px solid $gray;

    }
    &:hover .icon-checkbox {
      visibility: visible;
    }
    .item-title {
      font-size: 12px;
      position: absolute;
      left: 0;
      top: 65%;
      width: 100%;
    }

    a.item {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      margin: 0;
    }

  }
  .separator-item {
    float: left;
    padding-top: 4em;
    height: 120px;
  }
  .item-section {
    font-size: 12px;
    float: left;
    width: 100%;
    padding: 10px 0;
  }
  .is-selected {
    @include border-radius(3px);
    background-color: $theme_panel_selected_color;
    border: 1px solid transparent;
    cursor: pointer;
    .icon-checkbox {
      visibility: visible;
    }
    i, span {
      color: $white;
    }
  }

  .is-disabled {
    color: $gray;
    pointer-events: none;
    opacity: 0.6;
    &:hover {
      border: none;
      border-radius: 0px
    }
  }
}

@-webkit-keyframes glowlock {
  from {
    color: $white;
    text-shadow: -1px 1px 8px #ffc, 1px -1px 8px $white;
    opacity: 0.4;
  }
  50% {
    color: $white;
    text-shadow: -1px 1px 8px #ffc, 1px -1px 8px $white;
    opacity: 0.8;
  }
  to {
    color: $white;
    text-shadow: -1px 1px 8px #ffc, 1px -1px 8px $white;
    opacity: 1;
  }
}

@keyframes glowlock {
  from {
    color: $white;
    text-shadow: -1px 1px 8px #ffc, 1px -1px 8px $white;
    opacity: 0.4;
  }
  50% {
    color: $white;
    text-shadow: -1px 1px 8px #ffc, 1px -1px 8px $white;
    opacity: 0.8;
  }
  to {
    color: $white;
    text-shadow: -1px 1px 8px #ffc, 1px -1px 8px $white;
    opacity: 1;
  }
}
