//CSS for making the attachment button presentable
//http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
.file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-upload-label {
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  opacity: 0.5;
  // outline: 1px dotted #000;
  // outline: -webkit-focus-ring-color auto 5px;
}

.file-upload-label:hover {
  opacity: 1;
}

.messageSend-upload {
  text-align: left;
  vertical-align: bottom;
  margin: 0;
  padding: 0
}

.attachment-img-ideapanel{
  .fileAttachmentEditable{
   p{
    display:none;
   }
  }
  .dropdown-label{
    display:none;
  }
  .embeddedFile{
    img{
      width:100%;
    }
  }
  .dropdown{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .dropdown-menu{
    right: 0;
    left: auto;
  }
  .icon-arrowdown{
    font-size: 16px;
  }
}
.attachment-panel-idea{
  position: absolute;
  right: 16px;
  top: 18px;
}

.embedded-image-preview {
  max-width: 100%;
}

