.modal-token-vote-session .bbm-modal.bbm-modal--open {
  max-width: 850px;
  margin-top: 0;
  animation: none; /* required for the "position: fixed;" used on token bags to work properly */

  .popin-body {
    max-height: calc(90vh - 45px);
    overflow-y: auto;
    position: relative;
  }

  .submit-button-container {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.modal-token-vote-session-submitted .bbm-modal {
  max-width: 500px;

  .popin-body {
    text-align: center;
    padding-bottom: 0;
    p {
      font-size: 13px;
    }
    a {
      margin-top: 20px;
    }
  }
}

.token-vote-session.token-vote-form { /* used only by vote form page (not by vote results page) */
  
  .token-icon-show-like-zero-not-selected {
    vector-effect: non-scaling-stroke;
    stroke-width: 1;

    path.inner {
      fill: none;
      stroke: none;
    }

    path.outer {
      fill: none;
      stroke: #e9e9e9;
    }
  }

  .token-icon-show-like-zero-selected {
    vector-effect: non-scaling-stroke;
    stroke-width: 1;

    path.inner {
      fill: #ccc;
      stroke: none;
    }

    path.outer {
      fill: none;
      stroke: #ccc;
    }
  }

  /* !important is here to override inline style which would have been written in the SVG file which has been downloaded via its URL */
  .token-icon-show-like-positive-not-selected {
    /*
    fill: none !important;
    stroke: green !important;
    stroke-width: 1 !important;
    vector-effect: non-scaling-stroke !important;
    */
    /*!*/
  }

  .token-icon-show-like-positive-selected {
    /* show the SVG with its initial styles */
    /*!*/
  }

  .available-tokens-info {
    padding-top: 20px;
    font-size: 13px;
  }

  .available-tokens {
    padding-top: 7px;
    padding-bottom: 7px;
    
    &.fixed {
      position: fixed;
      z-index: 3682;
      top: 0;
      background: white;
      padding-bottom: 0;

      .border-effect {
        border-top: 1px solid rgba(200, 200, 200, 0.4);
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(200, 200, 200, 0.2)), to(transparent));
        -webkit-mask-box-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(0.5, rgba(200, 200, 200, 0.8)), color-stop(1, transparent));
        height: 5px;
        margin-top: 2px;
      }
    }

    .token-bags-content > div {
      display: table;
      border-spacing: 0 3px;
    }

    .help-text {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .token-bag-for-category {
      display: table-row;
    }

    .description, .available-tokens-icons {
      display: table-cell;
      vertical-align: middle;
      line-height: 100%;
    }
    .available-tokens-icons {
      padding-left: 10px;
      .token-icon {
        display: inline-block;
        margin-right: 5px;

        &.available:not(.animating-towards-not-available) {
          svg.token-icon-full {
            display: inline-block;
          }
          svg.token-icon-empty {
            display: none;
          }
        }

        &.not-available, &.animating-towards-not-available, &.animating-towards-available {
          svg.token-icon-full {
            display: none;
          }
          svg.token-icon-empty {
            display: inline-block;
          }
        }
      }
    }
    span.available-tokens-number {
      font-weight: 500;
      font-size: 150%;
    }

    .available:not(.animating-towards-not-available) {
      /* show the SVG with its initial styles */
      path, rect, circle {
        @extend .token-icon-show-like-positive-selected;
      }
    }

    .not-available:not(.animating-towards-available), .available.animating-towards-not-available, .not-available.animating-towards-available {
      path, rect, circle {
        @extend .token-icon-show-like-positive-not-selected;
      }
    }
  }



  .votables-collection {

    table {
      width: 100%;
    }
    th {
      color: #999;
      padding-bottom: 0;
    }
    tbody > div {
      display: table-row;
      border-bottom: 1px solid #f3f3f3;
    }
    tbody > div:last-child {
      border-bottom: none;
    }
    .left-column, .right-column {
      display: table-cell;
      vertical-align: top;
      padding-top: 12px;
      padding-bottom: 6px;
    }
    tbody > div:first-child {
      .left-column, .right-column {
        padding-top: 6px;
      }
    }
    .absorbing-column {
      width: 100%;
    }

    .tokens-for-idea {
      white-space: nowrap; /* this forces all token icons of the same category to display on the same line (no line return due to spaces in HTML between elements) */
      .token-categories.exclusive {
        .negative-tokens, .positive-tokens {
          display: inline-block;
        }
        .negative-tokens {
          direction: rtl; /* this way, the list is reversed :^) */
        }
      }
      .token-icon {
        padding: 0;
        margin: 0 5px 0 0; /* horizontal total margin should match tokenVoteSession.js::getTokenSize()::token_horizontal_margin */
        display: inline-block;
        vertical-align: baseline;
        border: 1px transparent;
        line-height: 0; /* avoids having a height of this element bigger than the height of its contents */


        &.animating-towards-not-selected svg.token-icon-full {
          display: none;
        }

        &.animating-towards-not-selected svg.token-icon-empty {
          display: inline-block;
        }

        &.animating-towards-selected svg.token-icon-full {
          display: none;
        }

        &.animating-towards-selected svg.token-icon-empty {
          display: inline-block;
        }

        &.selected:not(.hover):not(.animating-towards-not-selected) svg.token-icon-full {
          display: inline-block;
        }

        &.selected:not(.hover):not(.animating-towards-not-selected) svg.token-icon-empty {
          display: none;
        }

        &.not-selected:not(.hover):not(.animating-towards-selected) svg.token-icon-full {
          display: none;
        }

        &.not-selected:not(.hover):not(.animating-towards-selected) svg.token-icon-empty {
          display: inline-block;
        }

        &.not-selected.hover:not(.not-enough-available-tokens):not(.animating-towards-selected) svg.token-icon-full {
          display: inline-block;
        }

        &.not-selected.hover:not(.not-enough-available-tokens):not(.animating-towards-selected) svg.token-icon-empty {
          display: none;
        }

        &.selected.hover:not(.animating-towards-not-selected) svg.token-icon-full {
          display: inline-block;
        }

        &.selected.hover:not(.animating-towards-not-selected) svg.token-icon-empty {
          display: none;
        }


        &.not-enough-available-tokens {
          cursor: not-allowed;
        }
      }

      

      .token-category-allocation:not(.hover) .token-icon.custom {
        &.zero:not(.animating-towards-not-selected):not(.animating-towards-selected) {
          &.not-selected {
            @extend .token-icon-show-like-zero-not-selected;
          }
          &.selected {
            @extend .token-icon-show-like-zero-selected;
          }
        }
        &.positive {
          &.not-selected {
            path, rect, circle {
              @extend .token-icon-show-like-positive-not-selected;
            }
          }
          &.selected {
            path, rect, circle {
              @extend .token-icon-show-like-positive-selected;
            }
          }
        }

        &.animating-towards-selected {
          &.zero {
            /* show as if it was selected */
            @extend .token-icon-show-like-zero-selected;
          }
          &.positive {
            /* show as if it was not selected */
            path, rect, circle {
              @extend .token-icon-show-like-positive-not-selected;
            }
          }
        }

        &.animating-towards-not-selected {
          &.zero {
            /* show as if it was not selected */
            @extend .token-icon-show-like-zero-not-selected;
          }
          &.positive {
            /* show as if it was not selected */
            path, rect, circle {
              @extend .token-icon-show-like-positive-not-selected;
            }
          }
        }
      }

      .token-category-allocation.hover .token-icon.custom {
        &.zero {
          &:not(.hover) {
            /* show as if it was not selected */
            @extend .token-icon-show-like-zero-not-selected;
          }
          &.hover {
            /* show as if it was selected */
            @extend .token-icon-show-like-zero-selected;
          }
        }
        &.positive {
          &:not(.hover) {            
            &.selected:not(.animating-towards-not-selected) {
              opacity: 0.15;
            }
            &.not-selected, &.selected.animating-towards-not-selected, &.animating-towards-selected {
              path, rect, circle {
                @extend .token-icon-show-like-positive-not-selected;
              }
            }
          }
          &.hover {
            &:not(.animating-towards-selected) {
              /* show as if it was selected */
              path, rect, circle {
                @extend .token-icon-show-like-positive-selected;
              }
            }
            &.animating-towards-selected {
              /* show as if it was not selected */
              path, rect, circle {
                @extend .token-icon-show-like-positive-not-selected;
              }
            }
          }
        }
      }
    }
  }
}

.token-vote-session.token-vote-results { /* used only by vote results page (not by vote form page) */
  overflow-x: scroll;

  table {
    width: 100%;
  }
  th {
    //color: #999;
    color: $txtGray2;
    background-color: #fbfafa; //$gray3;
    padding-bottom: 0;
    padding-left: 5px;
    height: 30px;
  }
  tbody > div {
    display: flex;
    border-bottom: 1px solid #f3f3f3;
  }
  tbody > div:last-child {
    border-bottom: none;
  }
  
  
  .left-column, .right-column {
    vertical-align: top;
    padding-top: 12px;
    padding-bottom: 6px;
    padding-left: 5px;
  }

  .question-summary {
    .header{
      font-size: 13px;
      color: #999;
    }
    .content {
      font-size: 13px;
    }

    ul {
      list-style-position: inside;
      margin-top: 0px;
      padding-left: 5px;
    }
  }

  .votables-collection {

    &.js_vote-result-region {
      width: 100%;
      .thead {
        display: table-row;
        .left-column, .right-column {
          display: table-cell;
        }
      }
      .left-column {
        width: 60%;
      }
      .token-result-row {
        display: table-row;
        .left-column, .right-column {
          display: table-cell;
        }
        .vote-result {
          min-width: 150px;
        }

        .right-column {
          min-width: 80px;
        }
      }
    }

    .token-result-row {

      .token-vote-result-category-column {
        margin-left: 7px;
        margin-right: 3px; 
      }
    }

    .purple {
      color: $txtPurple;
      font-weight: bold;
    }
    .vote-category{
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

/*
  This is used to transfer the default theme color to the javascript components
  that will be created on the fly using D3.
 */
#js_vote-result-default-color{
  background-color: $theme_activelink_color;
}

.token-vote-session { /* used both by vote form page and by vote results page */
  .question-info {
    .question-title {
      @extend .h1;
      font-weight: 600;
    }
    .question-description {
      font-size: 13px;
    }
  }

  .votables-collection {
    margin-top: 10px;


    .idea-title {
      font-size: 15px;
      line-height: 1;
    }

    .idea-description {
      color: #999;
      font-size: 12px;

      .panel-editablebox {
        min-height: 2*$baseLineHeightFontMultiplier*$baseFontSize; // Should match the readMoreAfterHeightPx of the description's view
      }

      .seeMore, .seeLess {
        color: #999;
      }
    }
    
  }
}
